import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Form, InputNumber, Modal } from "antd";

import stringTitle from "../../stringTitle";
import CoinSelectBox from "../common/CoinSelectBox";
import {
  showCreateConfirm,
  showUpdateRefreshCloseConfirm
} from "../common/AlerInfoModal";
import { httpUrl } from "../../api/httpClient";
import { coinDivideFormat, coinMultiFormat } from "../../util";

const FormItem = Form.Item;
const CompetitionCoinModal = Form.create()(
  class extends Component {
    onSubmit = (e) => {
      const { form, page, onOk, coinData } = this.props;
      e.preventDefault();

      form.validateFields((err, values) => {
        // console.log(`coin values = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let data = {};
          if (page === "create") {
            data = {
              ...values,
              refIdx: 0,
              amount: coinMultiFormat(values.amount, values.coinType)
            };
            onOk(data);
            this.props.onCancel();
          }
          if (page === "add") {
            data = {
              ...values,
              refIdx: this.props.refIdx,
              amount: coinMultiFormat(values.amount, values.coinType)
            };
            showCreateConfirm(
              stringTitle.coinType + stringTitle.questTo + stringTitle.addQuest,
              httpUrl.competitionCoinCreate,
              data,
              this.props.refresh,
              this.props.onCancel
            );
          }
          if (page === "edit") {
            data = {
              ...values,
              idx: coinData.idx,
              amount: coinMultiFormat(values.amount, values.coinType)
            };
            showUpdateRefreshCloseConfirm(
              stringTitle.coinType +
                stringTitle.questTo +
                stringTitle.updateQuest,
              httpUrl.competitionCoinUpdate,
              data,
              this.props.refresh,
              this.props.onCancel,
              "update"
            );
          }
        }
      });
    };

    componentDidMount() {
      const { page, form, coinData } = this.props;

      if (page === "edit")
        form.setFieldsValue({
          ...coinData,
          amount: coinDivideFormat(coinData.amount, coinData.coinType)
        });
    }

    render() {
      const coinList = reactLocalStorage.getObject("coinList");
      const { visible, onCancel, form, page } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          onCancel={onCancel}
          title={stringTitle.coinType}
          style={{ width: 600 }}
          maskClosable={false}
          okText={
            page === "create"
              ? stringTitle.create
              : page === "add"
              ? stringTitle.add
              : stringTitle.update
          }
          onOk={this.onSubmit}
          cancelText={stringTitle.cancel}
        >
          <Form {...formItemLayout}>
            <FormItem label={<span>{stringTitle.coinType}&nbsp;</span>}>
              {getFieldDecorator("coinType", {
                rules: [
                  {
                    required: true,
                    message: stringTitle.coinTypePlaceholder
                  }
                ]
              })(
                <CoinSelectBox
                  placeholder={stringTitle.defaultPlaceholder}
                  coinCode={coinList.coinType}
                  style={{ width: 300 }}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.amount}&nbsp;</span>}>
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: stringTitle.amountPlaceholder
                  }
                ]
              })(
                <InputNumber
                  style={{ width: 300 }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default CompetitionCoinModal;
