import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Table, Button, Input, Checkbox } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import {
  numberFormat,
  formatDate,
  checkData,
  checkLevelName
} from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showDeletePageNumConfirm } from "../../components/common/AlerInfoModal";

import UserInfo from "../../components/user/UserInfo";
import UserDetail from "../../components/user/UserDetail";
import LevelSelectBox from "../../components/common/LevelSelectBox";

class MemberList extends Component {
  state = {
    levelList: [],

    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    level: 0,
    deleted: false,
    searchText: "",

    visible: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getUserList({ pageNum: pager.current });
  };

  // 등급 검색 설정
  userLevelHandleChange = (value) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      level: value
    });
  };

  // 삭제 포함여부 설정
  deletedHandleChange = (e) => {
    // console.log(`checked = ${JSON.stringify(e.target.checked)}`);
    this.setState({
      deleted: e.target.checked
    });
  };

  onDelete = (row) => {
    const { list, deleted, pagination } = this.state;

    let pageNum =
      row.deleted === 0 && deleted === false
        ? list.length === 1
          ? pagination.current === 1
            ? pagination.current
            : pagination.current - 1
          : pagination.current
        : pagination.current;

    showDeletePageNumConfirm(
      `${row.title.content}을 ${
        row.deleted ? stringTitle.restore : stringTitle.delete
      }하시겠습니까?`,
      httpUrl.userDelete,
      row,
      this.getUserList,
      pageNum
    );
  };

  // 회원 정보 리스트
  getUserList = (params = {}) => {
    const { level, deleted, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;
    console.log(`searchText= ${JSON.stringify(searchText, null, 4)}`);

    httpGet(httpUrl.getUserList, [10, pageNum, level, deleted, searchText], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    const levelList = reactLocalStorage.getObject("levelList");
    this.setState({
      levelList: [
        {
          idx: 0,
          level: 0,
          name: stringTitle.all
        },
        ...levelList
      ]
    });
    this.getUserList();
  }

  render() {
    const { visible, type, userData, levelList, level, list, pagination } =
      this.state;

    const columns = [
      {
        key: "user.idx",
        title: stringTitle.idx,
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.level,
        dataIndex: "user.level",
        className: "text-center",
        render: (data) => checkLevelName(data)
      },
      {
        title: stringTitle.name,
        dataIndex: "user.name",
        className: "text-center",
        render: (data, row) => (
          <UserInfo
            data={row.user}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                visible: true,
                userData: row.user,
                type: "user"
              });
            }}
          />
        )
      },
      {
        title: stringTitle.recommender,
        dataIndex: "recommender.name",
        className: "text-center",
        render: (data, row) =>
          row.recommender === null ? (
            checkData(row.recommender)
          ) : (
            <UserInfo
              data={row.recommender}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  visible: true,
                  userData: row.recommender,
                  type: "recommend"
                });
              }}
            />
          )
      },
      {
        title: stringTitle.joinDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      }
      // {
      //   title: stringTitle.status,
      //   dataIndex: "deleted",
      //   className: "text-center",
      //   render: (data, row) => (
      //     <SelectBox
      //       value={string.deleteString[data]}
      //       code={string.toggleCode}
      //       codeString={string.deleteString}
      //       onChange={(value) => {
      //         if (parseInt(value) !== data) {
      //           this.onDelete(row);
      //         }
      //       }}
      //     />
      //   )
      // }
    ];

    return (
      <div className="flex-row flex-column">
        {/* 회원정보 */}
        {visible && (
          <UserDetail
            visible={visible}
            userData={userData}
            type={type}
            page="user"
            onCancel={() => {
              this.setState({
                visible: false
              });
            }}
            refresh={this.getUserList}
          />
        )}
        <div className="title">{stringTitle.user + stringTitle.management}</div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label htmlFor="searchType" className="m-r-10">
            {stringTitle.level}&nbsp;
            <LevelSelectBox
              codeString={levelList}
              value={level}
              onChange={(value) => {
                if (value !== level) {
                  this.levelHandleChange(value);
                }
              }}
            />
          </label>
          <Input
            placeholder={stringTitle.defaultSearchPlaceholder}
            allowClear
            onChange={(e) => {
              this.setState({
                searchText: e.target.value
              });
            }}
            style={{ width: 300, marginRight: 10 }}
          />
          <Button
            onClick={() => {
              this.getUserList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <Table
          rowKey={(record) => record.user.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default MemberList;
