import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, Form, Modal } from "antd";

import stringTitle from "../../stringTitle";
import LevelSelectBox from "../common/LevelSelectBox";

import LevelUserSearchModal from "./LevelUserSearchModal";
import { showUpdateRefreshCloseConfirm } from "../common/AlerInfoModal";
import { httpUrl } from "../../api/httpClient";

const FormItem = Form.Item;
const LevelUpModal = Form.create()(
  class extends Component {
    state = { userFlag: false, userInfo: { user: {} } };

    onSubmit = (e) => {
      const { userInfo } = this.state;
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`values => ${JSON.stringify(values, null, 4)}`);
        if (!err)
          showUpdateRefreshCloseConfirm(
            userInfo.user.level < values.level
              ? stringTitle.levelupQuest
              : stringTitle.relegationQuest,
            httpUrl.levelupUpdate,
            values,
            this.props.refresh,
            this.props.onCancel,
            userInfo.user.level < values.level ? "levelup" : "relegation"
          );
      });
    };
    componentDidMount() {}

    render() {
      const levelList = reactLocalStorage.getObject("levelList");
      const { visible, onCancel, form } = this.props;
      const { userFlag, userInfo } = this.state;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          onCancel={onCancel}
          onOk={this.onSubmit}
          title={stringTitle.levelup}
          style={{ width: 600 }}
          maskClosable={false}
          okText={stringTitle.check}
          cancelText={stringTitle.cancel}
        >
          {userFlag && (
            <LevelUserSearchModal
              visible={userFlag}
              level={form.getFieldsValue().level}
              onCancel={() => {
                this.setState({ userFlag: false });
              }}
              onSelect={(userInfo) => {
                // console.log(`userInfo = ${JSON.stringify(userInfo, null, 4)}`);
                this.setState({ userInfo });
                form.setFieldsValue({
                  recommendCode: userInfo.user.recommendCode
                });
              }}
            />
          )}
          <Form {...formItemLayout}>
            <FormItem label={<span>{stringTitle.level}&nbsp;</span>}>
              {getFieldDecorator("level", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message:
                      stringTitle.level +
                      stringTitle.questTo +
                      stringTitle.defaultPlaceholder
                  }
                ]
              })(
                <LevelSelectBox
                  codeString={levelList}
                  placeholder={stringTitle.defaultPlaceholder}
                  style={{ width: 200 }}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.user}&nbsp;</span>}>
              {getFieldDecorator("recommendCode", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message:
                      stringTitle.user +
                      stringTitle.questTo +
                      stringTitle.defaultPlaceholder
                  }
                ]
              })(
                userInfo.user.idx !== undefined ? (
                  <div>
                    {userInfo.user.id} / {userInfo.user.name}
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        this.setState({ userFlag: true });
                      }}
                    >
                      {stringTitle.modify}
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      if (form.getFieldsValue().level !== undefined)
                        this.setState({ userFlag: true });
                      else
                        Modal.error({
                          title:
                            stringTitle.level +
                            stringTitle.questTo +
                            stringTitle.defaultPlaceholder
                        });
                    }}
                  >
                    {stringTitle.select}
                  </Button>
                )
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default LevelUpModal;
