import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Modal, Descriptions, Tabs, Table, Select, Input, Button } from "antd";

import { httpUrl, httpGet } from "../../api/httpClient";
import {
  amountFormat,
  checkCoinName,
  checkData,
  checkLevelName,
  formatDate,
  numberFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";

const { TabPane } = Tabs;
const Option = Select.Option;
const Ditems = Descriptions.Item;
class UserDetail extends Component {
  state = {
    data: {},
    isUpdateFlag: false,

    walletList: [],
    walletPagination: { total: 0, current: 0 },
    walletLogList: [],
    walletLogPagination: { total: 0, current: 0 },
    coinList: [
      {
        coinType: 0,
        name: stringTitle.all
      },
      ...reactLocalStorage.getObject("coinList")
    ],
    coinType: 0,
    transferType: "ALL",
    searchType: "ALL",
    searchText: "",
    competitionList: [],
    competitionPagination: { total: 0, current: 0 }
  };

  onChangeModal = (value, type) => {
    var data = this.state.data;
    data[type] = value;
    this.setState({
      data
    });
  };

  // 회원 정보 api
  getUserDetail = () => {
    const { page, userData } = this.props;
    let url = "";
    if (page === "user") url = httpUrl.userSelect;

    httpGet(url, [userData.id], {})
      .then((res) => {
        // console.log(`user detail res = ${JSON.stringify(res, null, 4)}`);
        this.setState({ data: res.data });
        this.getUserWalletList();
        this.getUserWalletLogList({ serial: res.data.serial });
        this.getUserCompetitionList();
        this.getUserLevelupList();
      })
      .catch((error) => {});
  };

  componentDidMount() {
    // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
    this.getUserDetail();
  }

  render() {
    const levelList = reactLocalStorage.getObject("levelList");
    const { visible, onCancel } = this.props;
    const { data } = this.state;

    return (
      <Modal
        visible={visible}
        title={`${stringTitle.user} ${stringTitle.info}`}
        onCancel={onCancel}
        footer={null}
        style={{ minWidth: "1400px" }}
      >
        {/* 회원 정보 */}
        <Descriptions
          className="m-b-10"
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >
          <Ditems label={stringTitle.createDate} span={2}>
            {formatDate(data.createDate)}
          </Ditems>
          <Ditems
            label={`${stringTitle.id} (${stringTitle.recommendCode})`}
          >{`${data.id} (${data.recommendCode})`}</Ditems>
          <Ditems
            label={`${stringTitle.recommender} (${stringTitle.recommendCode})`}
          >
            {`${checkData(data.recommenderName)} (${data.recommender})`}
          </Ditems>
          <Ditems label={stringTitle.name} className="flex-row flex-center">
            {checkData(data.name)}
          </Ditems>
          <Ditems label={stringTitle.phone}>{checkData(data.phone)}</Ditems>
          <Ditems label={stringTitle.level} span={2}>
            {levelList.find((item) => item.level === data.level) &&
              levelList.find((item) => item.level === data.level).name}
          </Ditems>
          <Ditems label={stringTitle.memo} span={2}>
            {data.memo}
          </Ditems>
        </Descriptions>
        <div style={{ clear: "both", height: 10 }} />
        <Tabs defaultActiveKey="1" onChange={() => {}}>
          <TabPane tab={stringTitle.wallet} key="1">
            {/* 회원 지갑 정보 */}
            {this.renderWalletList()}
          </TabPane>
          <TabPane tab={stringTitle.transfer} key="2">
            {/* 회원 거래 내역 */}
            {this.renderWalletLogList()}
          </TabPane>
          <TabPane tab={stringTitle.competition} key="3">
            {/* 회원 대회 신청 내역 */}
            {this.renderCompetition()}
          </TabPane>
          <TabPane tab={stringTitle.levelup} key="4">
            {/* 회원 승격 내역 */}
            {this.renderLevelup()}
          </TabPane>
        </Tabs>
      </Modal>
    );
  }

  // 회원 지갑 페이징
  handleWalletTableChange = (walletPagination, filters, sorter) => {
    const pager = { ...this.state.walletPagination };
    pager.current = walletPagination.current;
    this.setState({
      walletPagination: pager
    });

    this.getUserWalletList({ pageNum: pager.current });
  };

  // 회원 지갑 정보 api
  getUserWalletList = (params = {}) => {
    const { page, userData } = this.props;
    let pageNum = params.pageNum || 1;

    let url = "";
    if (page === "user") url = httpUrl.getUserWalletList;

    httpGet(url, [10, pageNum, userData.idx], {})
      .then((res) => {
        // console.log(`user wallet List = ${JSON.stringify(res.data, null, 4)}`);
        const walletPagination = { ...this.state.walletPagination };
        walletPagination.current = res.data.currentPage;
        walletPagination.total = res.data.totalCount;
        this.setState({
          walletList: res.data.walletList,
          walletPagination
        });
      })
      .catch((e) => {});
  };

  // 회원 지갑
  renderWalletList() {
    const { walletList, walletPagination } = this.state;

    const colums = [
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        key: "coinType",
        title: stringTitle.coinType,
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: stringTitle.wallet,
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: stringTitle.balance,
        dataIndex: "balance",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      }
    ];

    return (
      <Table
        rowKey={(record) => record.coinType}
        columns={colums}
        dataSource={walletList}
        pagination={walletPagination}
        onChange={this.handleWalletTableChange}
        style={{ marginBottom: "10px" }}
      />
    );
  }

  // 회원 거래 페이징
  handleWalletLogTableChange = (walletPagination, filters, sorter) => {
    const pager = { ...this.state.walletPagination };
    pager.current = walletPagination.current;
    this.setState({
      walletPagination: pager
    });

    this.getUserWalletLogList({ pageNum: pager.current });
  };

  // 전송 종류 선택
  handleChangeTranferType = (value) => {
    this.setState({
      transferType: value
    });
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value
    });
  };

  // 검색 조건 선택
  handleChangeSearchType = (value) => {
    if (value === "SERVER") {
      this.setState({
        searchText: "",
        searchType: value
      });
    } else {
      this.setState({
        searchType: value
      });
    }
  };

  // 검색어 입력
  handleChangeSearchText = (value) => {
    this.setState({
      searchText: value
    });
  };

  // 회원 거래 정보 api
  getUserWalletLogList = (params = {}) => {
    const { transferType, coinType, searchType, searchText } = this.state;
    const { page } = this.props;
    let pageNum = params.pageNum || 1;

    let url = "";
    if (page === "user") url = httpUrl.getUserWalletLogList;

    httpGet(
      url,
      [
        10,
        pageNum,
        params.serial,
        transferType,
        coinType,
        searchType,
        searchText
      ],
      {}
    )
      .then((res) => {
        // console.log(`user walletLog = ${JSON.stringify(res.data, null, 4)}`);
        const walletLogPagination = { ...this.state.walletLogPagination };
        walletLogPagination.current = res.data.currentPage;
        walletLogPagination.total = res.data.totalCount;
        this.setState({
          walletLogList: res.data.walletLogList,
          walletLogPagination
        });
      })
      .catch((e) => {});
  };

  // 회원 거래내역
  renderWalletLogList() {
    const {
      coinList,
      coinType,
      transferType,
      searchType,
      walletLogList,
      walletLogPagination
    } = this.state;

    const colums = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.coinType + stringTitle.type,
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: stringTitle.send + stringTitle.type,
        dataIndex: "type",
        className: "text-center",
        render: (data) => <div>{string.sendString[data]}</div>
      },
      {
        title: stringTitle.from,
        dataIndex: "from",
        className: "text-center",
        render: (data) => (
          <div style={{ wordWrap: "break-word", width: "200px" }}>{data}</div>
        )
      },
      {
        title: stringTitle.to,
        dataIndex: "to",
        className: "text-center",
        render: (data) => (
          <div style={{ wordWrap: "break-word", width: "200px" }}>{data}</div>
        )
      },
      {
        title: stringTitle.amount,
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: stringTitle.transfer + stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data, row) => <div>{string.requestLogString[data]}</div>
      },
      {
        width: 200,
        title: stringTitle.memo,
        dataIndex: "memo",
        className: "text-center",
        render: (data) => (
          <div style={{ wordWrap: "break-word", width: "200px" }}>
            {checkData(data)}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="flex-row flex-center flex-end m-b-10">
          <label className="m-r-10">
            {stringTitle.coinType}&nbsp;
            <Select
              value={coinType}
              style={{ width: "160px" }}
              onChange={this.handleChangeCoinType}
            >
              {coinList.map((coin) => (
                <Option key={coin.coinType} value={coin.coinType}>
                  {coin.name}
                </Option>
              ))}
            </Select>
          </label>
          <label className="m-r-10">
            {stringTitle.send} {stringTitle.type}&nbsp;
            <SelectBox
              style={{ width: "160px" }}
              value={string.transferString[transferType]}
              codeString={string.transferString}
              onChange={this.handleChangeTranferType}
            />
          </label>
          <SelectBox
            value={string.walletLogString[searchType]}
            code={string.walletLogCode}
            codeString={string.walletLogString}
            onChange={this.handleChangeSearchType}
            style={{ width: "200px" }}
          />
          <Input
            placeholder="보낸 지갑주소, 받은 지갑주소을 입력하세요"
            disabled={searchType === "SERVER"}
            onChange={(e) => {
              this.handleChangeSearchText(e.target.value);
            }}
            allowClear
            style={{ width: "300px" }}
          />
          <Button
            onClick={() => {
              this.getUserWalletLogList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.coinType}
          columns={colums}
          dataSource={walletLogList}
          pagination={walletLogPagination}
          onChange={this.handleWalletLogTableChange}
          style={{ marginBottom: "10px" }}
        />
      </div>
    );
  }

  // 회원 지갑 페이징
  handleCompetitionTableChange = (competitionPagination, filters, sorter) => {
    const pager = { ...this.state.competitionPagination };
    pager.current = competitionPagination.current;
    this.setState({
      competitionPagination: pager
    });

    this.getUserCompetitionList({ pageNum: pager.current });
  };

  // 회원 대회 내역 api
  getUserCompetitionList = (params = {}) => {
    const { page, userData } = this.props;
    let pageNum = params.pageNum || 1;

    let url = "";
    if (page === "user") url = httpUrl.getUserCompetitionList;

    httpGet(url, [10, pageNum, userData.idx], {})
      .then((res) => {
        // console.log(`user comp List = ${JSON.stringify(res.data, null, 4)}`);
        const competitionPagination = { ...this.state.competitionPagination };
        competitionPagination.current = res.data.currentPage;
        competitionPagination.total = res.data.totalCount;
        this.setState({
          competitionList: res.data.list,
          competitionPagination
        });
      })
      .catch((e) => {});
  };

  // 회원 대회 신청 내역
  renderCompetition() {
    const { competitionList, competitionPagination } = this.state;

    const colums = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center",
        render: (data, row) => {
          return (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  competitonFlag: true,
                  selectData: row,
                  page: "edit"
                });
              }}
            >
              {data.find((item) => item.language === "ko").content}
            </div>
          );
        }
      },
      {
        title: stringTitle.application_period,
        dataIndex: "startDate",
        className: "text-center",
        render: (data, row) => (
          <div>
            {formatDate(data)} ~ {formatDate(row.endDate)}
          </div>
        )
      },
      {
        title: stringTitle.joinLevel,
        dataIndex: "startLevel",
        className: "text-center",
        render: (data, row) => (
          <div>
            {checkLevelName(data)} ~ {checkLevelName(row.endLevel)}
          </div>
        )
      },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => string.competitionRequestString[data]
      }
    ];

    return (
      <div>
        <Table
          rowKey={(record) => record.amount}
          columns={colums}
          dataSource={competitionList}
          pagination={competitionPagination}
          style={{ marginBottom: "10px" }}
        />
      </div>
    );
  }

  getUserLevelupList = () => {
    const { page, userData } = this.props;

    let url = "";
    if (page === "user") url = httpUrl.getUserLevelupList;

    httpGet(url, [userData.idx], {})
      .then((res) => {
        this.setState({
          levelupList: res.data
        });
      })
      .catch((e) => {});
  };

  // 회원 지급내역 -> To가 회원인 경우
  renderLevelup() {
    const { levelupList } = this.state;

    const colums = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.level,
        dataIndex: "level",
        className: "text-center",
        render: (data) => checkLevelName(data)
      },
      {
        title: stringTitle.recommendCode,
        dataIndex: "recommendCode",
        className: "text-center",
        render: (data) => checkData(data)
      },

      {
        title: stringTitle.levelupDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      }
    ];

    return (
      <div>
        <Table
          rowKey={(record) => record.idx}
          columns={colums}
          dataSource={levelupList}
          pagination={false}
          style={{ marginBottom: "10px" }}
        />
      </div>
    );
  }
}

export default UserDetail;
