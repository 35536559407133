import React, { Component } from "react";

import { Form, InputNumber, Modal } from "antd";

import { httpUrl } from "../../api/httpClient";
import { coinDivideFormat, coinMultiFormat } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";
import CoinSelectBox from "../common/CoinSelectBox";
import { showUpdateRefreshCloseConfirm } from "../common/AlerInfoModal";

const FormItem = Form.Item;
const AutoModal = Form.create()(
  class extends Component {
    onSubmit = (e) => {
      const { form, data } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        let body = {
          ...data,
          ...values,
          amount: coinMultiFormat(values.amount, values.coinType)
        };
        // console.log(`body = ${JSON.stringify(body, null, 4)}`);
        if (!err)
          showUpdateRefreshCloseConfirm(
            stringTitle.updateQuest,
            httpUrl.coinAutoUpdate,
            body,
            this.props.refresh,
            this.props.onCancel,
            "update"
          );
      });
    };

    componentDidMount() {
      const { form, data } = this.props;
      form.setFieldsValue({
        ...data,
        active: data.active.toString(),
        amount: coinDivideFormat(data.amount, data.coinType)
      });
    }

    render() {
      const { visible, onCancel, form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          onCancel={onCancel}
          onOk={this.onSubmit}
          title={stringTitle.auto + stringTitle.distribute}
          cancelText={stringTitle.cancel}
          okText={stringTitle.update}
          maskClosable={false}
        >
          <Form {...formItemLayout}>
            <FormItem label={<span>{stringTitle.dropType}&nbsp;</span>}>
              {getFieldDecorator("dropType", {
                rules: [
                  {
                    required: false
                  }
                ]
              })(<div>{form.getFieldsValue().dropType}</div>)}
            </FormItem>
            <FormItem label={<span>{stringTitle.coinType}&nbsp;</span>}>
              {getFieldDecorator("coinType", {
                rules: [
                  {
                    required: true,
                    message: "코인를 선택해주세요!"
                  }
                ]
              })(
                <CoinSelectBox
                  style={{ width: 200 }}
                  placeholder={stringTitle.defaultPlaceholder}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.amount}&nbsp;</span>}>
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: "수량을 입력해주세요!"
                  }
                ]
              })(
                <InputNumber
                  min={0}
                  style={{ width: 200 }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.active}&nbsp;</span>}>
              {getFieldDecorator("active", {
                rules: [
                  {
                    required: true,
                    message: "선택해주세요!"
                  }
                ]
              })(
                <SelectBox
                  style={{ width: 200 }}
                  code={string.toggleCode}
                  codeString={string.activeHandlesCodes}
                />
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);
export default AutoModal;
