import React, { Component } from "react";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import crypto from "crypto";

import { login } from "../../actions/loginAction";
import { Descriptions, Input, Button, Modal } from "antd";

import { httpPut, httpUrl } from "../../api/httpClient";

import { checkData, formatDate } from "./../../util";
import stringTitle from "../../stringTitle";

import InputPassword from "../../components/common/InputPassword";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

const Ditems = Descriptions.Item;
class MyInfo extends Component {
  state = {
    userInfo: {},
    isUpdate: false,
    updateInfo: {},
    pwd: "",
    newPwd: null,
    otpVisble: false
  };

  onChangeModal = (value, type) => {
    var data = Object.assign(this.state.updateInfo);
    data[type] = value;
    this.setState({
      updateInfo: data
    });
    // console.log(`updateData = ${JSON.stringify(data, null, 4)}`);
  };

  onUpdate = () => {
    const { updateInfo, pwd, newPwd } = this.state;
    if (pwd === "") {
      alert(stringTitle.nowPwd);
    } else if (pwd === newPwd) {
      alert(stringTitle.samePwd);
    } else if (pwd !== newPwd || newPwd === null) {
      let password = pwd + updateInfo.userId;
      let hashedPassword = crypto
        .createHash("sha256")
        .update(password, "utf8")
        .digest()
        .toString("hex");
      let hashedNewPassword = "";
      if (newPwd !== null) {
        let newPassword = newPwd + updateInfo.userId;
        hashedNewPassword = crypto
          .createHash("sha256")
          .update(newPassword, "utf8")
          .digest()
          .toString("hex");
      }
      let updateData = {
        ...updateInfo,
        password: newPwd === null ? newPwd : hashedNewPassword,
        prePassword: hashedPassword
      };
      // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
      Modal.confirm({
        width: 500,
        title: stringTitle.updateQuest,
        okText: stringTitle.check,
        onOk() {
          httpPut(httpUrl.adminUserUpdate, [], updateData)
            .then((res) => {
              // console.log(`res = ${JSON.stringify(res, null, 4)}`);
              if (res.data.result === "SUCCESS") {
                Modal.info({
                  title: stringTitle.updateAnw,
                  okText: stringTitle.check,
                  onOk() {
                    delete updateData.prePassword;
                    this.props.onLogin({ ...updateData });
                    this.setState({
                      pwd: "",
                      newPwd: null,
                      isUpdate: false
                    });
                  }
                });
              } else ShowErrorAlert(res.data.data);
            })
            .catch((error) => {
              ShowErrorAlert(error.response.data.message || error.message);
            });
        },
        cancelText: stringTitle.cancel,
        onCancel() {}
      });
    }
  };

  componentDidMount() {
    this.setState({ userInfo: reactLocalStorage.getObject("adminUser") });
  }

  render() {
    const { otpVisble, userInfo, isUpdate, updateInfo } = this.state;
    const coinList = reactLocalStorage.getObject("coinList");

    return (
      <div>
        <div className="title">{stringTitle.myInfo}</div>
        {otpVisble && (
          <InputPassword
            visible={otpVisble}
            onCancel={() => {
              this.setState({ otpVisble: false });
            }}
          />
        )}
        <div className="wrapper mt-35 width-1240" />
        {/* <div>{JSON.stringify(userInfo)}</div> */}
        <div style={{ clear: "both" }} />
        <Descriptions
          bordered
          key={userInfo.userId}
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >
          <Ditems label={stringTitle.createDate}>
            {formatDate(userInfo.createDate)}
          </Ditems>
          <Ditems label={stringTitle.id}>{userInfo.userId}</Ditems>
          <Ditems label={stringTitle.name}>
            {isUpdate ? (
              <Input
                value={userInfo.name}
                onChange={(e) => {
                  this.onChangeModal(e.target.value, "name");
                }}
              />
            ) : (
              checkData(userInfo.name)
            )}
          </Ditems>
          <Ditems label={stringTitle.email}>
            {isUpdate ? (
              <Input
                value={updateInfo.email}
                onChange={(e) => {
                  this.onChangeMoedal(e.target.value, "email");
                }}
              />
            ) : (
              checkData(userInfo.email)
            )}
          </Ditems>
          <Ditems label={stringTitle.ip}>{userInfo.ipAddress}</Ditems>
          <Ditems label={stringTitle.phone}>
            {isUpdate ? (
              <Input
                value={updateInfo.mobile}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^0-9]+$/g, "");
                  this.onChangeModal(value, "mobile");
                }}
              />
            ) : (
              checkData(userInfo.mobile)
            )}
          </Ditems>
          {isUpdate && (
            <Ditems label={stringTitle.password}>
              <Input.Password
                value={this.state.pwd}
                placeholder={stringTitle.nowPwd}
                onChange={(e) => {
                  this.setState({ pwd: e.target.value });
                }}
              />
            </Ditems>
          )}
          {isUpdate && (
            <Ditems label={stringTitle.newPassword}>
              <Input.Password
                value={this.state.newPwd}
                placeholder={stringTitle.newPwd}
                onChange={(e) => this.setState({ newPwd: e.target.value })}
              />
            </Ditems>
          )}
          <Ditems label={stringTitle.coinType + stringTitle.auth} span={2}>
            {userInfo.authCoinList !== undefined &&
              userInfo.authCoinList.map((list, index) => {
                let coinName = "";
                coinList.forEach((coin) => {
                  if (coin.coinType === list) {
                    coinName = coin.name;
                  }
                });
                return userInfo.authCoinList.length - 1 === index
                  ? coinName
                  : coinName + ", ";
              })}
          </Ditems>
          <Ditems label={stringTitle.access + stringTitle.auth} span={2}>
            {userInfo.authList !== undefined &&
              userInfo.authList.map((menu) => {
                return (
                  <div key={menu.idx} style={{ marginBottom: "10px" }}>
                    <div>- {menu.nameKr}</div>
                    <div style={{ paddingLeft: "30px" }}>
                      {menu.subMenu !== null &&
                        menu.subMenu.map((subMenu, index) => (
                          <span style={{ marginRight: "0px" }}>
                            {subMenu.nameKr}
                            {index !== menu.subMenu.length - 1 && " / "}
                          </span>
                        ))}
                    </div>
                  </div>
                );
              })}
          </Ditems>
        </Descriptions>
        {isUpdate ? (
          <div style={{ float: "right", width: "150px", marginTop: "10px" }}>
            <Button
              style={{ marginRight: "10px" }}
              onClick={() =>
                this.setState({
                  pwd: "",
                  newPwd: null,
                  isUpdate: false,
                  userInfo: userInfo
                })
              }
            >
              {stringTitle.cancel}
            </Button>
            <Button onClick={() => this.onUpdate()}>
              {stringTitle.modify}
            </Button>
          </div>
        ) : (
          <Button
            style={{ float: "right", width: "150px", marginTop: "10px" }}
            onClick={() =>
              this.setState({ isUpdate: true, updateInfo: userInfo })
            }
          >
            {stringTitle.updateFlag}
          </Button>
        )}
        <div style={{ clear: "both", height: "10px" }} />
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyInfo);
