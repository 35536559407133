import { Modal } from "antd";
import stringTitle from "../../stringTitle";

export function showAlert(alertType, refresh) {
  let title = "";
  if (alertType === "create") title = stringTitle.createAnw;
  if (alertType === "regist") title = stringTitle.registAnw;
  if (alertType === "update") title = stringTitle.updateAnw;
  if (alertType === "modify") title = stringTitle.modifyAnw;
  if (alertType === "delete") title = stringTitle.deleteAnw;
  if (alertType === "restore") title = stringTitle.restoreAnw;
  if (alertType === "add") title = stringTitle.addAnw;
  if (alertType === "charge") title = stringTitle.chargeAnw;
  if (alertType === "offer") title = stringTitle.offerAnw;

  if (alertType === "UNKNOWN_TYPE") title = stringTitle.imageUnknown;
  if (alertType === "SAVE_FAIL") title = stringTitle.imageFail;

  if (alertType === "cancel") title = stringTitle.cancelAnw;

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {}
  });
}

export function showRefreshAlert(alertType, refresh) {
  let title = "";
  if (alertType === "create") title = stringTitle.createAnw;
  if (alertType === "regist") title = stringTitle.registAnw;
  if (alertType === "update") title = stringTitle.updateAnw;
  if (alertType === "modify") title = stringTitle.modifyAnw;
  if (alertType === "delete") title = stringTitle.deleteAnw;
  if (alertType === "restore") title = stringTitle.restoreAnw;
  if (alertType === "reward") title = stringTitle.deleteAnw;
  if (alertType === "accept") title = stringTitle.acceptAnw;
  if (alertType === "reject") title = stringTitle.rejectAnw;
  if (alertType === "levelup") title = stringTitle.levelupAnw;
  if (alertType === "relegation") title = stringTitle.relegationAnw;

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      refresh({ refresh: true });
    }
  });
}

export function showCloseRefreshAlert(alertType, refresh, onCancel) {
  let title = "";
  if (alertType === "create") title = stringTitle.createAnw;
  if (alertType === "regist") title = stringTitle.registAnw;
  if (alertType === "update") title = stringTitle.updateAnw;
  if (alertType === "modify") title = stringTitle.modifyAnw;
  if (alertType === "reward") title = stringTitle.rewardAnw;
  if (alertType === "accept") title = stringTitle.acceptAnw;
  if (alertType === "reject") title = stringTitle.rejectAnw;
  if (alertType === "levelup") title = stringTitle.levelupAnw;
  if (alertType === "relegation") title = stringTitle.relegationAnw;

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      refresh({ refresh: true });
      onCancel();
    }
  });
}

export function showCloseAlert(alertType, onCancel) {
  let title = "";
  if (alertType === "create") title = stringTitle.createAnw;
  if (alertType === "regist") title = stringTitle.registAnw;
  if (alertType === "update") title = stringTitle.updateAnw;
  if (alertType === "modify") title = stringTitle.modifyAnw;
  if (alertType === "reward") title = stringTitle.rewardAnw;
  if (alertType === "accept") title = stringTitle.acceptAnw;
  if (alertType === "reject") title = stringTitle.rejectAnw;

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      onCancel();
    }
  });
}

export function showIdxAlert(alertType, refresh, idx) {
  let title = "";
  if (alertType === "update") title = stringTitle.updateAnw;
  if (alertType === "modify") title = stringTitle.modifyAnw;

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      refresh({ idx });
    }
  });
}

export function showPageNumAlert(alertType, refresh, pageNum) {
  let title = "";
  if (alertType === "delete") title = stringTitle.deleteAnw;
  if (alertType === "restore") title = stringTitle.restoreAnw;
  if (alertType === "accept") title = stringTitle.acceptAnw;
  if (alertType === "reject") title = stringTitle.rejectAnw;

  Modal.info({
    title: title,
    okText: stringTitle.check,
    onOk() {
      refresh({ pageNum });
    }
  });
}
