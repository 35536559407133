import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, DatePicker, Input, Table } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpGet, httpUrl } from "../../api/httpClient";
import {
  checkLevelName,
  endDateFormat,
  formatDate,
  numberFormat,
  startDateFormat,
  startMonthBeforeFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import LevelSelectBox from "../../components/common/LevelSelectBox";
import { showDeleteIdxConfirm } from "../../components/common/AlerInfoModal";

import CompetitionModal from "../../components/competition/CompetitionModal";
import CompetitionRequset from "../../components/competition/CompetitionRequset";
import CompetitionCoinAddress from "../../components/competition/CompetitionCoinAddress";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

const RangePicker = DatePicker.RangePicker;
class CompetitionList extends Component {
  constructor(props) {
    super(props);
    const levelList = reactLocalStorage.getObject("levelList");
    let date = new Date();
    this.state = {
      levelList: [
        {
          idx: 0,
          level: 0,
          name: stringTitle.all
        },
        levelList
      ],

      list: [],
      pagination: { current: 0, total: 0 },
      // startDate: "",
      startDate: startMonthBeforeFormat(date),
      endDate: endDateFormat(date),
      level: 0,
      searchText: "",
      language: global.lanList[0],

      competitonFlag: false,
      requestFlag: false,
      coinAddressFlag: false
    };
  }

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getCompetitionList({ pageNum: pager.current });
  };

  // 검색 기간 설정
  handleChangeDate = (date) => {
    this.setState({
      startDate: date.length !== 0 ? startDateFormat(date[0]) : "",
      endDate: date.length !== 0 ? endDateFormat(date[1]) : ""
    });
  };

  // 검색 대상 설정
  levelHandleChange = (value) => {
    this.setState({
      level: value
    });
  };

  // 검색
  searchTextHandleChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  onDelete = (data) => {
    showDeleteIdxConfirm(
      data.deleted ? stringTitle.restoreQuest : stringTitle.deleteQuest,
      httpUrl.competitionDelete,
      data,
      this.getCompetitionList
    );
  };

  getCompetitionList = (params = {}) => {
    const { startDate, endDate, level, searchText } = this.state;
    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getCompetitionList,
      [10, pageNum, startDate, endDate, level, searchText],
      {}
    )
      .then((res) => {
        // console.log(`competition = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getCompetitionList();
  }

  render() {
    const {
      competitonFlag,
      page,
      selectData,
      requestFlag,
      refIdx,
      coinAddressFlag,
      levelList,
      startDate,
      endDate,
      level,
      list,
      pagination
    } = this.state;
    const dateFormat = "YYYY-MM-DD";

    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center",
        render: (data, row) => {
          return (
            <div
              className="pointer"
              onClick={() => {
                this.setState({
                  competitonFlag: true,
                  selectData: row,
                  page: "edit"
                });
              }}
            >
              {data.find((item) => item.language === "ko").content}
            </div>
          );
        }
      },
      {
        title: stringTitle.application_period,
        dataIndex: "startDate",
        className: "text-center",
        render: (data, row) => (
          <div>
            {formatDate(data)} ~ {formatDate(row.endDate)}
          </div>
        )
      },
      {
        title: stringTitle.joinLevel,
        dataIndex: "startLevel",
        className: "text-center",
        render: (data, row) => (
          <div>
            {checkLevelName(data)}~{checkLevelName(row.endLevel)}
          </div>
        )
      },
      {
        title: (
          <div>
            {stringTitle.application_status} <br />({stringTitle.requestPerson}/
            {stringTitle.accept}/{stringTitle.reject})
          </div>
        ),
        dataIndex: "application_status",
        className: "text-center",
        render: (data, row) => (
          <div
            className="pointer"
            onClick={() => {
              this.setState({ requestFlag: true, refIdx: row.idx });
            }}
          >
            <div>
              {numberFormat(
                row.requestCount + row.acceptCount + row.rejectCount
              )}
            </div>
            ( {numberFormat(row.requestCount)}/{numberFormat(row.acceptCount)}/
            {numberFormat(row.rejectCount)} )
          </div>
        )
      },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.coinType,
        dataIndex: "address",
        className: "text-center",
        render: (data, row) => (
          <Button
            className="pointer"
            onClick={() => {
              this.setState({ coinAddressFlag: true, refIdx: row.idx });
            }}
          >
            {stringTitle.coinType}
          </Button>
        )
      },
      {
        title: stringTitle.status,
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <SelectBox
            value={string.deleteString[data]}
            code={string.toggleCode}
            codeString={string.deleteString}
            onChange={(value) => {
              if (parseInt(value) !== row.deleted) {
                this.onDelete(row);
              }
            }}
          />
        )
      }
    ];

    return (
      <div>
        {competitonFlag && (
          <CompetitionModal
            page={page}
            visible={competitonFlag}
            selectData={selectData}
            onCancel={() => {
              this.setState({ competitonFlag: false });
            }}
            refresh={this.getCompetitionList}
          />
        )}
        {requestFlag && (
          <CompetitionRequset
            visible={requestFlag}
            refIdx={refIdx}
            onCancel={() => {
              this.setState({ requestFlag: false });
            }}
            refresh={this.getCompetitionList}
          />
        )}
        {coinAddressFlag && (
          <CompetitionCoinAddress
            visible={coinAddressFlag}
            refIdx={refIdx}
            onCancel={() => {
              this.setState({ coinAddressFlag: false });
            }}
          />
        )}
        <div className="title">
          {stringTitle.competition + stringTitle.management}
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ competitonFlag: true, page: "create" });
            }}
          >
            {stringTitle.create}
          </Button>
          <div>
            <label className="m-r-10">
              {stringTitle.searchDate}&nbsp;
              <RangePicker
                locale={locale}
                defaultValue={[
                  moment(startDate, dateFormat),
                  moment(endDate, dateFormat)
                ]}
                format={dateFormat}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
                allowClear={true}
              />
            </label>
            {levelList.length !== 0 && (
              <LevelSelectBox
                codeString={levelList}
                value={level}
                style={{ marginRight: 10 }}
                onChange={(value) => {
                  if (value !== level) {
                    this.levelHandleChange(value);
                  }
                }}
              />
            )}
            <Input
              style={{ width: 250, marginRight: 10 }}
              placeholder="검색어를 입력하세요"
              onChange={(e) => {
                this.searchTextHandleChange(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                this.getCompetitionList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default CompetitionList;
