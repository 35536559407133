const stringTitle = {
  // 메뉴
  login: "로그인",
  management: "관리",
  competition: "대회",
  user: "회원",
  level: "등급",
  levelup: "승급",
  coinDistribute: "배포목록",
  url: "URL",

  // 기본
  info: "정보",
  idx: "순번",
  title: "제목",
  content: "내용",
  view: "보기",
  preView: "미리보기",
  viewDetail: "상세보기",
  excelDownload: "엑셀 다운로드",
  downloadFail: "다운로드 실패",
  excelUpload: "엑셀 업로드",
  fileUpload: "파일 업로드",
  fileUploadFail: "파일 업로드 실패",
  formDownload: "양식 다운로드",
  multiUploadInfo: "* 여러 사람에게 배포 시 파일업로드 사용",
  multiLockUploadInfo: "* 여러 사람에게 락 생성 시 파일업로드 사용",
  image: "이미지",
  language: "언어",
  bank: "은행",
  bankAccount: "계좌번호",
  bankAccountName: "예금주",
  location: "장소",
  type: "종류",
  total: "총",
  etc: "기타",

  // 검색 조건
  search: "검색",
  searchRequire: "검색조건",
  deletedInclude: "삭제 포함",
  searchDate: "검색기간",

  // 요일
  date: "날짜",
  day: "일별",
  week: "주별",
  month: "월별",
  createDate: "생성일",
  updateDate: "갱신일",
  levelupDate: "승격일",
  requireDate: "요청일",
  releaseDate: "해제일",
  transferDate: "거래일",

  // placeholder
  defaultPlaceholder: "선택해주세요",
  defaultSearchPlaceholder: "검색어를 입력해주세요.",
  userPlaceholder: "회원의 아이디 / 이름을 입력해주세요.",
  titlePlaceholder: "제목을 입력해주세요.",
  contentPlaceholder: "내용을 입력해주세요.",
  coinTypePlaceholder: "코인 종류를 선택해주세요.",
  amountPlaceholder: "수량을 입력해주세요.",
  memoPlaceholder: "메모를 입력해주세요.",
  addressPlaceholder: "주소를 입력해주세요.",
  releaseDatePlaceholder: "해제일을 선택해주세요.",
  releaseDaysPlaceholder: "해제기간을 선택해주세요.",
  urlPlaceholder: "URL을 입력해주세요.",

  // 정렬
  orderBy: "정렬",
  all: "전체",
  asc: "정순",
  desc: "역순",

  // toggle
  withdraw: "탈퇴",
  generalManager: "일반관리자",
  superAdmin: "슈퍼관리자",
  possible: "가능",
  impossible: "불가능",
  certify: "인증",
  unCerity: "미인증",
  active: "활성화",
  hold: "비활성화",

  // 상태
  status: "상태",
  select: "선택",
  check: "확인",
  create: "생성",
  regist: "등록",
  update: "수정",
  modify: "변경",
  cancel: "취소",
  add: "추가",
  delete: "삭제",
  selectFlag: "선택하기",
  createFlag: "생성하기",
  updateFlag: "수정하기",
  deleteFlag: "삭제하기",
  deleted: "삭제 여부",
  restore: "복구",
  wait: "대기",
  request: "요청",
  pending: "전송 중",
  success: "성공",
  fail: "실패",
  accept: "승인",
  reject: "거절",
  complete: "완료",
  result: "결과",
  offer: "신청",

  // question
  questTo: "을/를 ",
  createQuest: "생성하시겠습니까?",
  registQuest: "등록하시겠습니까?",
  updateQuest: "수정하시겠습니까?",
  modifyQuest: "변경하시겠습니까?",
  deleteQuest: "삭제하시겠습니까?",
  restoreQuest: "복구하시겠습니까?",
  sendQuest: "배포하시겠습니까?",
  addQuest: "추가하시겠습니까?",
  requestQuest: "신청하시겠습니까?",
  chargeQuest: "충전하시겠습니까?",
  levelupQuest: "승격하시겠습니까?",
  relegationQuest: "강등하시겠습니까?",
  rewardQuest: "정산하시겠습니까?",
  acceptQuest: "대회신청을 승인하시겠습니까?",
  rejectQuest: "대회신청을 거절하시겠습니까?",
  unlockQuest: "해제하시겠습니까?",

  // answer
  createAnw: "생성이 완료되었습니다.",
  registAnw: "등록이 완료되었습니다.",
  updateAnw: "수정이 완료되었습니다.",
  modifyAnw: "변경이 완료되었습니다.",
  deleteAnw: "삭제가 완료되었습니다.",
  restoreAnw: "복구가 완료되었습니다.",
  sendAnw: "배포가 완료되었습니다.",
  chargeAnw: "충전이 완료되었습니다.",
  offerAnw: "신청이 완료되었습니다.",
  rewardAnw: "정산이 완료되었습니다.",
  addAnw: "추가 되었습니다.",
  cancelAnw: "취소 요청되었습니다.",
  acceptAnw: "승인처리 되었습니다.",
  rejectAnw: "거절처리 되었습니다.",
  levelupAnw: "승격처리 되었습니다.",
  relegationAnw: "강등처리 되었습니다.",
  imageUnknown:
    "이미지 형식이 올바르지 않습니다.\njpg, png, bmp 형식의 파일을 올려주세요.",
  imageFail: "이미지 저장에 실패하였습니다.",
  unlockAnw: "해제되었습니다",

  // 대회
  application_period: "신청기간",
  joinLevel: "참가등급",
  application_status: "신청현황",
  requestPerson: "신청자",
  competitionDate: "대회 일시",
  competitonPrice: "참가비(원화)",

  RECMND: "추천가입(추천자)",
  RECMNDED: "추천가입",
  NOT: "미추천가입",

  // 회원
  joinDate: "가입일",
  id: "아이디",
  name: "이름",
  recommendCode: "추천코드",
  phone: "전화번호",
  address: "주소",
  email: "이메일",
  recommender: "추천인",
  memo: "메모",
  balance: "잔액",
  transfer: "거래",
  send: "전송",

  // 지갑
  wallet: "지갑",
  from: "보낸 지갑",
  to: "받은 지갑",
  fee: "수수료",
  errorMsg: "에러 메시지",
  cautionFrom: "송신 제한",
  cautionTo: "수신 제한",
  foundation: "재단",
  I2I: "내부 전송",
  I2E: "외부 송금",
  E2I: "외부 입금",
  S2I: "샵 전송",

  lockIdx: "Lock번호",
  releaseDays: "해제기간(일)",
  lastDayAmount: "마지막 날 수량",
  unlock: "해지",
  holdingStatus: "보유 현황",
  situation: "현황",
  quantity_available: "출금가능수량",
  foundation_Info1:
    "* 사용자가 잔액 갱신을 하지 않을 경우 총 수량이 일치 하지 않을 수 있습니다.",
  foundation_Info2:
    " * 전체잠금이 설정된 기간동안은 개별 락의 상태와 관계없이 해당 지갑의 해당 코인에 대해 모든 출금이 금지됩니다.",

  //코인
  coinType: "코인",
  price: "금액",
  amount: "수량",
  manual: "수동",
  auto: "자동",
  distribute: "배포",
  distributeFlag: "배포하기",
  adminId: "배포자",
  coinLockInfo: "코인을 배포하면서 락을 설정합니다.",
  coinLockExample: "a주소에 8-15일부터 10일간 매일 100/10 개의 락이 해제된다.",
  airdrop: "에어드랍",
  dropType: "배포종류",
  sign_up_fee: "회원가입 폴리곤 수수료 지급",
  sign_up: "회원가입 코인 지급",
  sign_up_rec: "회원가입 추천인 지급",

  // 커뮤니티
  note: "공지사항",
  news: "소식",
  evnt: "이벤트",
  read: "조회수",
  division: "구분",
  mainCheck: "메인 노출",

  // 관리자
  admin: "관리자",
  password: "비밀번호",
  newPassword: "새 비밀번호",
  ip: "IP주소",
  mobile: "핸드폰번호",
  auth: "권한",
  access: "접근",
  setting: "설정",
  area: "영역",
  service: "서비스",
  action: "활동",
  message: "메세지",
  differentPassword: "비밀번호가 다릅니다!",

  // 내정보
  myInfo: "마이페이지",
  otp: "OTP",
  across: "접근",
  nowPwd: "현재 비밀번호를 입력해주세요",
  samePwd: "현재 비밀번호와 같습니다. 다시 입력해주세요",
  newPwd: "새로운 비밀번호를 입력해주세요"
};
export default stringTitle;
