import React, { Component } from "react";

class UserInfo extends Component {
  render() {
    const { onClick, style, data } = this.props;

    return (
      <div
        className="flex-row flex-center justify-center"
        onClick={onClick}
        style={style}
      >
        <div className="font-bold">
          {data.id}
          <br />
          {data.name}&nbsp;({data.recommendCode})
        </div>
      </div>
    );
  }
}

export default UserInfo;
