import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, DatePicker, Form, Input, Modal } from "antd";
import moment from "moment";

import { httpUrl } from "../../api/httpClient";
import { endDateFormat, startDateFormat } from "../../util";

import stringTitle from "../../stringTitle";
import LevelSelectBox from "../common/LevelSelectBox";
import {
  showCreateConfirm,
  showUpdateRefreshCloseConfirm
} from "../common/AlerInfoModal";

import CoinTags from "./CoinTags";
import CompetitionCoinModal from "./CompetitionCoinModal";

const FormItem = Form.Item;
const CompetitionModal = Form.create()(
  class extends Component {
    state = {
      coinFlag: false,
      coinInfo: []
    };

    onSubmit = (e) => {
      const { form, page, selectData } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`values = ${JSON.stringify(values, null, 4)}`);
        let body = {
          ...values,
          endLevel: values.startLevel,
          title: [{ idx: 0, content: values.title, language: "ko" }],
          content: [{ idx: 0, content: values.content, language: "ko" }],
          startDate: startDateFormat(values.startDate),
          endDate: endDateFormat(values.endDate)
        };

        if (page === "edit")
          body = {
            ...body,
            idx: selectData.idx,
            title: [
              {
                idx: selectData.title[0].idx,
                content: values.title,
                language: "ko"
              }
            ],
            content: [
              {
                idx: selectData.content[0].idx,
                content: values.content,
                language: "ko"
              }
            ]
          };
        // console.log(`body = ${JSON.stringify(body, null, 4)}`);
        if (!err) {
          if (page === "create")
            showCreateConfirm(
              stringTitle.competition + stringTitle.registQuest,
              httpUrl.competitionCreate,
              body,
              this.props.refresh,
              this.props.onCancel
            );
          if (page === "edit")
            showUpdateRefreshCloseConfirm(
              stringTitle.competition + stringTitle.updateQuest,
              httpUrl.competitionUpdate,
              body,
              this.props.refresh,
              this.props.onCancel,
              "update"
            );
        }
      });
    };

    componentDidMount() {
      const { form, page, selectData } = this.props;
      // console.log(`selectData= ${JSON.stringify(selectData, null, 4)}`);

      if (page === "edit") {
        form.setFieldsValue({
          ...selectData,
          title: selectData.title[0].content,
          content: selectData.content[0].content,
          startDate: moment(selectData.startDate),
          endDate: moment(selectData.endDate)
        });
      }
    }

    render() {
      const levelList = reactLocalStorage.getObject("levelList");
      const { visible, onCancel, form, page } = this.props;
      const { coinFlag, coinInfo } = this.state;
      const { getFieldDecorator } = form;
      const dateFormat = "YYYY-MM-DD";

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          onCancel={onCancel}
          title={stringTitle.competition}
          style={{ minWidth: "1000px" }}
          maskClosable={false}
          onOk={this.onSubmit}
          okText={page === "create" ? stringTitle.create : stringTitle.update}
          cancelText={stringTitle.cancel}
        >
          {coinFlag && (
            <CompetitionCoinModal
              page="create"
              visible={coinFlag}
              onCancel={() => {
                this.setState({ coinFlag: false });
              }}
              onOk={(data) => {
                let coinInfo = this.state.coinInfo;
                coinInfo.push(data);
                this.setState({ coinInfo });
                form.setFieldsValue({ competitionCoin: coinInfo });
              }}
            />
          )}
          <Form {...formItemLayout}>
            <FormItem label={<span>{stringTitle.title}&nbsp;</span>}>
              {getFieldDecorator("title", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: stringTitle.titlePlaceholder
                  }
                ]
              })(<Input allowClear />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.content}&nbsp;</span>}>
              {getFieldDecorator("content", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: stringTitle.contentPlaceholder
                  }
                ]
              })(<Input.TextArea rows={4} />)}
            </FormItem>
            <FormItem
              label={<span>{stringTitle.application_period}&nbsp;</span>}
              style={{ marginBottom: 0 }}
            >
              <FormItem style={{ display: "inline-block", marginRight: 10 }}>
                {getFieldDecorator("startDate", {
                  rules: [
                    {
                      required: true,
                      message:
                        stringTitle.application_period +
                        stringTitle.questTo +
                        stringTitle.defaultPlaceholder
                    }
                  ]
                })(<DatePicker format={dateFormat} placeholder="Start" />)}
              </FormItem>
              ~
              <FormItem style={{ display: "inline-block", marginLeft: 10 }}>
                {getFieldDecorator("endDate", {
                  rules: [
                    {
                      required: true,
                      message:
                        stringTitle.application_period +
                        stringTitle.questTo +
                        stringTitle.defaultPlaceholder,
                      validator: (rule, value, cb) => {
                        if (value < form.getFieldsValue().startDate)
                          cb("신청 기간을 다시 선택해주세요");
                        cb();
                      }
                    }
                  ]
                })(<DatePicker format={dateFormat} placeholder="End" />)}
              </FormItem>
            </FormItem>
            <FormItem label={<span>{stringTitle.competitionDate}&nbsp;</span>}>
              {getFieldDecorator("competitionDate", {
                rules: [
                  {
                    required: true,
                    message:
                      stringTitle.competitionDate +
                      stringTitle.questTo +
                      stringTitle.defaultPlaceholder
                  }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.location}&nbsp;</span>}>
              {getFieldDecorator("location", {
                rules: [
                  {
                    required: true,
                    message: "대회 장소를 입력해주세요."
                  }
                ]
              })(<Input />)}
            </FormItem>
            <FormItem
              label={<span>{stringTitle.joinLevel}&nbsp;</span>}
              style={{ marginBottom: 0 }}
            >
              <div>
                <FormItem style={{ display: "inline-block" }}>
                  {getFieldDecorator("startLevel", {
                    rules: [
                      {
                        required: true,
                        message:
                          stringTitle.joinLevel +
                          stringTitle.questTo +
                          stringTitle.defaultPlaceholder
                      }
                    ]
                  })(
                    <LevelSelectBox
                      placeholder={stringTitle.defaultPlaceholder}
                      codeString={levelList}
                      style={{ width: 200, marginRight: 10 }}
                    />
                  )}
                </FormItem>
                {/* ~
                  <FormItem style={{ display: "inline-block" }}>
                    {getFieldDecorator("endLevel", {
                      rules: [
                        {
                          required: true,
                          message:
                            stringTitle.joinLevel +
                            stringTitle.questTo +
                            stringTitle.defaultPlaceholder,
                          validator: (rule, value, cb) => {
                            if (form.getFieldsValue().endLevel === undefined)
                              cb(
                                stringTitle.joinLevel +
                                  stringTitle.questTo +
                                  stringTitle.defaultPlaceholder
                              );
                            if (value < form.getFieldsValue().startLevel)
                              cb("참가등급을 다시 선택해주세요");
                          }
                        }
                      ]
                    })(
                      <LevelSelectBox
                        placeholder={stringTitle.defaultPlaceholder}
                        codeString={levelList}
                        style={{ width: 200, marginLeft: 10 }}
                      />
                    )}
                  </FormItem> */}
              </div>
            </FormItem>
            <FormItem label={<span>{stringTitle.competitonPrice}&nbsp;</span>}>
              <div style={{ marginBottom: 0 }}>
                <FormItem
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    marginRight: 10
                  }}
                >
                  {getFieldDecorator("bank", {
                    rules: [
                      {
                        required: false,
                        message: "은행을 입력해주세요."
                      }
                    ]
                  })(<Input placeholder={stringTitle.bank} />)}
                </FormItem>
                <FormItem style={{ display: "inline-block", marginBottom: 0 }}>
                  {getFieldDecorator("bankAccount", {
                    rules: [
                      {
                        required: false,
                        message: "계좌번호를 입력해주세요."
                      }
                    ]
                  })(
                    <Input
                      placeholder={stringTitle.bankAccount}
                      style={{ width: 300 }}
                    />
                  )}
                </FormItem>
              </div>
              <FormItem
                style={{
                  display: "inline-block",
                  marginBottom: 0,
                  marginRight: 10
                }}
              >
                {getFieldDecorator("accountName", {
                  rules: [
                    {
                      required: false,
                      message: "예금주를 입력해주세요."
                    }
                  ]
                })(<Input placeholder={stringTitle.bankAccountName} />)}
              </FormItem>
              <FormItem style={{ display: "inline-block", marginBottom: 0 }}>
                {getFieldDecorator("price", {
                  rules: [
                    {
                      required: false,
                      message: "참가비(원화)를 입력해주세요."
                    }
                  ]
                })(
                  <Input
                    placeholder={stringTitle.competitonPrice}
                    style={{ width: 300 }}
                  />
                )}
              </FormItem>
            </FormItem>
            {page === "create" && (
              <FormItem label={<span>{stringTitle.coinType}&nbsp;</span>}>
                {getFieldDecorator("competitionCoin", {
                  rules: [
                    {
                      required: true,
                      message: stringTitle.coinTypePlaceholder
                    }
                  ]
                })(
                  <div>
                    <Button
                      onClick={() => {
                        this.setState({ coinFlag: true });
                      }}
                    >
                      {stringTitle.select}
                    </Button>
                    <br />
                    {coinInfo.length !== 0 && <CoinTags list={coinInfo} />}
                  </div>
                )}
              </FormItem>
            )}
          </Form>
        </Modal>
      );
    }
  }
);

export default CompetitionModal;
