import React, { Component } from "react";
import stringTitle from "../../stringTitle";

class ManualInfo extends Component {
  render() {
    return (
      <div>
        <div style={{ color: "blue" }}>
          {stringTitle.address}, {stringTitle.amount}, {stringTitle.releaseDate}
          , {stringTitle.releaseDays}
        </div>
        <div style={{ color: "blue" }}>a, 100, 2022-08-15, 10</div>
        <div style={{ color: "blue" }}>{stringTitle.coinLockExample}</div>
      </div>
    );
  }
}

export default ManualInfo;
