import React, { Component } from "react";

import { Button, Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { checkCoinName, coinDivideFormat, numberFormat } from "../../util";

import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";
import { showDeleteIdxConfirm } from "../common/AlerInfoModal";

import CompetitionCoinModal from "./CompetitionCoinModal";

class CompetitionCoinAddress extends Component {
  state = {
    list: []
  };

  onDelete = (data) => {
    showDeleteIdxConfirm(
      data.deleted ? stringTitle.restoreQuest : stringTitle.deleteQuest,
      httpUrl.competitionCoinDelete,
      data,
      this.getCompetitionCoinList
    );
  };

  getCompetitionCoinList = (params = {}) => {
    const { refIdx } = this.props;

    httpGet(httpUrl.getCompetitionCoinList, [refIdx], {})
      .then((res) => {
        // console.log(`competition coin = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getCompetitionCoinList();
  }

  render() {
    const { visible, onCancel, refIdx } = this.props;
    const { coinFlag, page, coinData, list } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.coinType,
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => checkCoinName(data)
      },
      {
        title: stringTitle.address,
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: stringTitle.amount,
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) =>
          numberFormat(coinDivideFormat(data, row.coinType))
      },
      {
        dataIndex: "update",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({
                coinFlag: true,
                page: "edit",
                coinData: row
              });
            }}
          >
            {stringTitle.update}
          </Button>
        )
      },
      {
        title: stringTitle.status,
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <SelectBox
            value={string.deleteString[data]}
            code={string.toggleCode}
            codeString={string.deleteString}
            onChange={(value) => {
              if (parseInt(value) !== row.deleted) {
                this.onDelete(row);
              }
            }}
          />
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        title={stringTitle.coinType}
        style={{ minWidth: "1000px" }}
        footer={false}
        maskClosable={false}
      >
        {coinFlag && (
          <CompetitionCoinModal
            page={page}
            visible={coinFlag}
            refIdx={refIdx}
            coinData={coinData}
            onCancel={() => {
              this.setState({ coinFlag: false });
            }}
            refresh={this.getCompetitionCoinList}
          />
        )}
        <div className="flex-row flex-center m-b-10">
          <Button
            onClick={() => {
              this.setState({ coinFlag: true, page: "add" });
            }}
          >
            {stringTitle.add}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default CompetitionCoinAddress;
