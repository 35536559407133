import React, { Component } from "react";

import { Button, Input, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { checkLevelName, formatDate, numberFormat } from "../../util";
import stringTitle from "../../stringTitle";

import UserInfo from "../../components/user/UserInfo";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import LevelUpModal from "../../components/level/LevelUpModal";

class LevelupLogList extends Component {
  state = {
    list: [],
    pagination: { total: 0, current: 0 },
    searchText: "",

    visible: false
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getLevelupLogList({ pageNum: pager.current });
  };

  // 검색
  searchTextHandleChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  getLevelupLogList = (params = {}) => {
    const { searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getLevelupLogList, [10, pageNum, searchText], {})
      .then((res) => {
        // console.log(`levelup = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getLevelupLogList();
  }

  render() {
    const { visible, list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        dataIndex: "idx",
        title: stringTitle.idx,
        className: "text-center",
        render: (data) => numberFormat(data)
      },
      {
        dataIndex: "level",
        title: stringTitle.level,
        className: "text-center",
        render: (data) => checkLevelName(data)
      },
      {
        dataIndex: "user",
        title: stringTitle.user,
        className: "text-center",
        render: (data) => <UserInfo data={data} />
      },
      {
        dataIndex: "createDate",
        title: stringTitle.levelupDate,
        className: "text-center",
        render: (data) => formatDate(data)
      }
    ];

    return (
      <div>
        {visible && (
          <LevelUpModal
            visible={visible}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            refresh={this.getLevelupLogList}
          />
        )}
        <div className="title">
          {stringTitle.levelup + stringTitle.management}
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ visible: true });
            }}
          >
            {stringTitle.levelup}
          </Button>
          <div className="flex-row flex-center">
            <Input
              allowClear
              placeholder={stringTitle.defaultSearchPlaceholder}
              style={{ width: 300 }}
              onChange={(e) => {
                this.searchTextHandleChange(e.target.value);
              }}
            />
            <Button onClick={() => this.getLevelupLogList()}>
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default LevelupLogList;
