import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload
} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from "moment";

import { httpPost, httpUrl, serverUrl } from "../../api/httpClient";
import { coinMultiFormat, formatDateSecond } from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

import UserSearch from "./UserSearch";
import ManualInfo from "../../pages/coinManagement/ManualInfo";

const FormItem = Form.Item;
const Option = Select.Option;
const DailyLockModal = Form.create()(
  class extends Component {
    state = {
      coinList: [],
      coinType: 0,
      validateAddress: false,
      userVisible: false
    };

    // 코인 종류 선택
    handleChangeCoinType = (value) => {
      this.setState({
        coinType: value
      });
    };

    // 마감일 제한
    disabledDate = (current) => {
      // Can not select days before today and today
      return current < moment().endOf("day" - 1);
    };

    // 생성
    onSumbit = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          let body = {
            ...values,
            amount: coinMultiFormat(values.amount, values.coinType),
            releaseDate: formatDateSecond(values.releaseDate),
            transferFlag: false
          };
          Modal.confirm({
            title: stringTitle.createQuest,
            okText: stringTitle.create,
            cancelText: stringTitle.cancel,
            onOk() {
              httpPost(httpUrl.dailyLockSet, [], body)
                .then((res) => {
                  Modal.info({
                    title: stringTitle.createAnw,
                    okText: stringTitle.check,
                    onOk() {
                      if (this.props.searchText !== "")
                        this.props.lockRefresh();
                      this.props.requestRefresh();
                      this.props.onCancel();
                    }
                  });
                })
                .catch((error) => {
                  ShowErrorAlert(error.response.data.message || error.message);
                });
            }
          });
        }
      });
    };

    componentDidMount() {
      let coinList = reactLocalStorage.getObject("coinList");
      let arr = [];
      coinList.forEach((coin) => {
        if (coin.category != "MAINNET") arr.push({ ...coin });
      });

      this.setState({ coinList: arr });
    }

    render() {
      const { coinList, coinType, userVisible } = this.state;
      const {
        form,
        visible,
        onCancel,
        searchText,
        lockRefresh,
        requestRefresh
      } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };
      const uploadProps = {
        action: serverUrl + httpUrl.dailyLockSetExcel,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file) => {
          if (global.confirm("DAILY LOCK을 생성하시겠습니까?")) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: (file) => {
          // console.log(`file = ${JSON.stringify(file, null, 4)}`);
          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s = `${stringTitle.total} : ${total}, ${stringTitle.success} : ${success}, ${stringTitle.fail} : ${fail}`;
          Modal.info({
            title: `DAILY LOCK ${stringTitle.result}`,
            content: (
              <div>
                <p>
                  {file.data.failAddresses.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- ${stringTitle.fail} ${stringTitle.result}\n`}
                      {file.data.failAddresses.length > 0 &&
                        file.data.failAddresses.map((value) => (
                          <div>{value}</div>
                        ))}
                    </div>
                  ) : (
                    <div>{s}</div>
                  )}
                </p>
              </div>
            ),
            onOk() {
              if (searchText !== "") lockRefresh();
              requestRefresh();
              onCancel();
            },
            okText: stringTitle.check
          });
        },
        onError(err) {
          Modal.error({
            title: "DAILY LOCK 결과",
            content: stringTitle.fileUploadFail
          });
        }
      };

      return (
        <Modal
          visible={visible}
          title={`DAILY LOCK ${stringTitle.create}`}
          okText={stringTitle.create}
          cancelText={stringTitle.cancel}
          onOk={this.onSumbit}
          onCancel={onCancel}
          style={{ minWidth: "800px" }}
        >
          {userVisible && (
            <UserSearch
              visible={userVisible}
              coinType={coinType}
              onCancel={() => this.setState({ userVisible: false })}
              onOk={(value) => {
                this.setState({ address: value });
                form.setFieldsValue({ address: value });
              }}
            />
          )}
          <Form {...formItemLayout}>
            <div
              className="flex-row flex-center justify-center"
              style={{ marginBottom: "10px" }}
            >
              <span style={{ color: "blue" }}>
                {stringTitle.multiUploadInfo}
              </span>
            </div>
            <div className="flex-row flex-center justify-center m-b-10">
              <a href="/setDailyLockExcelFomat.xlsx" download>
                <Button className="m-r-10">
                  <Icon type="download" style={{ fontSize: "20px" }} />
                  {stringTitle.formDownload}
                </Button>
              </a>
              <Upload
                {...uploadProps}
                accept=".xlsx, .xls"
                showUploadList={false}
              >
                <Button className="m-r-10">
                  <Icon type="upload" style={{ fontSize: "20px" }} />
                  {stringTitle.excelUpload}
                </Button>
              </Upload>
            </div>
            <div
              className="flex-row flex-center justify-center"
              style={{ marginTop: "20px", color: "blue" }}
            >
              해당 주소가 보유한 수량에 대해 락을 설정합니다.
            </div>
            {/* <FormItem label={<span></span>} colon={false}>
            </FormItem> */}
            {Array.isArray(coinList) && (
              <FormItem
                label={
                  <span>
                    {stringTitle.coinType} {stringTitle.type}&nbsp;
                  </span>
                }
              >
                {getFieldDecorator("coinType", {
                  rules: [
                    {
                      required: true,
                      message: stringTitle.coinTypePlaceholder
                    }
                  ]
                })(
                  <Select
                    style={{ width: "200px" }}
                    value={coinType !== 0 && coinType}
                    placeholder={stringTitle.defaultPlaceholder}
                    onChange={this.handleChangeCoinType}
                  >
                    {coinList.map((coin) => (
                      <Option key={coin.coinType} value={coin.coinType}>
                        {coin.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            )}
            <FormItem label={<span>{stringTitle.address}&nbsp;</span>}>
              {getFieldDecorator("address", {
                rules: [
                  {
                    required: true,
                    message: stringTitle.addressPlaceholder
                  }
                ]
              })(
                <Input
                  allowClear
                  style={{ width: "360px" }}
                  placeholder={stringTitle.addressPlaceholder}
                />
              )}
            </FormItem>
            <FormItem label={<span></span>} colon={false}>
              <Button
                onClick={() => {
                  if (coinType !== 0) {
                    this.setState({ userVisible: true });
                  } else if (coinType === 0)
                    alert(stringTitle.coinTypePlaceholder);
                }}
              >
                {stringTitle.user} {stringTitle.search}
              </Button>
            </FormItem>
            <FormItem label={<span>{stringTitle.amount}&nbsp;</span>}>
              {getFieldDecorator("amount", {
                rules: [
                  {
                    required: true,
                    message: stringTitle.amountPlaceholder
                  },
                  {
                    validator: (rule, value, cb) => {
                      if (
                        value &&
                        Array.isArray(coinList.coinType) &&
                        coinType === 0
                      ) {
                        cb(stringTitle.coinTypePlaceholder);
                      }
                      cb();
                    }
                  }
                ]
              })(
                <InputNumber
                  allowClear
                  style={{ width: "200px" }}
                  placeholder="10.03"
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.releaseDate}&nbsp;</span>}>
              {getFieldDecorator("releaseDate", {
                rules: [
                  {
                    required: true,
                    message: stringTitle.releaseDatePlaceholder
                  }
                ]
              })(
                <DatePicker
                  style={{ width: "200px" }}
                  placeholder={stringTitle.releaseDatePlaceholder}
                  disabledDate={this.disabledDate}
                  locale={locale}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.releaseDate}&nbsp;</span>}>
              {getFieldDecorator("releaseDays", {
                rules: [
                  {
                    required: true,
                    message: stringTitle.releaseDaysPlaceholder
                  }
                ]
              })(
                <InputNumber
                  allowClear
                  style={{ width: "200px" }}
                  placeholder="10"
                />
              )}
            </FormItem>
            <FormItem label={<span></span>} colon={false}>
              <ManualInfo />
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default DailyLockModal;
