import React, { Component } from "react";

import { Button, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { checkCoinName, coinDivideFormat, numberFormat } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import AutoModal from "../../components/coin/AutoModal";

class Auto extends Component {
  state = {
    list: [],

    updateFlag: false,
    selectData: {}
  };

  // onHandelActive = () =>{}

  // 자동 배포 목록 조회
  getCoinAutoList = (params = {}) => {
    httpGet(httpUrl.getCoinAutoList, [], {})
      .then((res) => {
        // console.log(`res = ${JSON.stringify(res, null, 4)}`);
        this.setState({
          list: res.data
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getCoinAutoList();
  }

  render() {
    const { updateFlag, selectData, list } = this.state;
    const columns = [
      {
        key: "dropType",
        title: stringTitle.dropType,
        dataIndex: "dropType",
        className: "text-center",
        render: (data) => string.autoString[data]
      },
      {
        key: "coinType",
        title: stringTitle.coinType,
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => checkCoinName(data)
      },
      {
        title: stringTitle.amount,
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) =>
          numberFormat(coinDivideFormat(data, row.coinType))
      },
      {
        title: stringTitle.status,
        dataIndex: "active",
        className: "text-center",
        render: (data) => string.activeHandlesCodes[data]
      },
      {
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              this.setState({ updateFlag: true, selectData: row });
            }}
          >
            {stringTitle.updateFlag}
          </Button>
        )
      }
    ];

    return (
      <div>
        {updateFlag && (
          <AutoModal
            visible={updateFlag}
            data={selectData}
            onCancel={() => {
              this.setState({ updateFlag: false });
            }}
            refresh={this.getCoinAutoList}
          />
        )}
        <div className="title">
          {stringTitle.auto} {stringTitle.distribute}
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.dropType + record.coinType}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
    );
  }
}

export default Auto;
