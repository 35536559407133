import Axios from "axios";
import util from "util";
import Const from "../const";
import { reactLocalStorage } from "reactjs-localstorage";
let loadingCount = 0;

global.language = "ko";
global.lanList = ["ko", "en"];

const serverUrl =
  Const.serverProtocol + "://" + Const.serverIp + ":" + Const.serverPort;

const makeUrl = (url, params) => {
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const httpExec = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1)
    global.document.getElementById("loadingSpinner").style.display = "block";

  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      })
      .catch((error) => {
        // console.log(JSON.stringify(error, null, 4));
        if (error.message.includes("401")) {
          alert("로그인이 만료되었습니다. 다시 로그인해주세요");
          reactLocalStorage.remove("adminUser");
          global.location.href = "/";
        }
        reject(error);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById("loadingSpinner").style.display =
            "none";
      });
  });
};

const httpGet = (url, params, data) => {
  return httpExec("GET", makeUrl(url, params), data);
};

const httpPut = (url, params, data) => {
  return httpExec("PUT", makeUrl(url, params), data);
};

const httpPost = (url, params, data) => {
  return httpExec("POST", makeUrl(url, params), data);
};

const httpDownload = (url, params, data) => {
  // return httpExec('GET', makeUrl(url, params), data);
  return new Promise((resolve, reject) => {
    Axios({
      method: "GET",
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      responseType: "arraybuffer"
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpUrl = {
  // login
  idPwdCheck: "/idPwCheck",
  login: "/login",
  logout: "/logout",

  //blockchain coin decimal
  getCoinDecimal: "/blockchain/list/decimal?language=%s",
  getSearchSerial: "/foundation/search?coinType=%s&address=%s",

  //image
  imageUpload: "/image/upload",
  getImage: "/image/",
  imageEncoding: "/image/imageEncoding",

  // competition
  getCompetitionList:
    "/competition/list?pageSize=%s&pageNum=%s&startDate=%s&endDate=%s&level=%s&searchText=%s",
  competitionCreate: "/competition/create",
  competitionUpdate: "/competition/update",
  competitionDelete: "/competition/delete/%s",
  getCompetitionCoinList: "/competition/coin/list/%s",
  competitionCoinCreate: "/competition/coin/create",
  competitionCoinUpdate: "/competition/coin/update",
  competitionCoinDelete: "/competition/coin/delete/%s",
  getCompetitionRequestList:
    "/competition/apply/list?pageSize=%s&pageNum=%s&refIdx=%s&status=%s&searchText=%s",
  competitionRequsetStatusUpdate: "/competition/apply/update/status",

  // user - 회원
  getUserList:
    "/user/list?pageSize=%s&pageNum=%s&level=%s&deleted=%s&searchText=%s",
  userSelect: "/user/select/%s",
  userUpdate: "/user/update",
  userDelete: "/user/delete",
  getUserWalletList: "/user/walletListByIdx?pageSize=%s&pageNum=%s&idx=%s",
  getUserWalletLogList:
    "/user/walletLogList?pageSize=%s&pageNum=%s&serial=%s&transferType=%s&coinType=%s&type=%s&searchText=%s",
  getUserCompetitionList:
    "/user/competition/list?pageSize=%s&pageNum=%s&idx=%s",
  getUserLevelupList: "/user/level/up/log/%s",

  // level
  getLevelListLanguage: "/level/list/language?language=%s",
  getLevelList: "/level/list",
  levelCreate: "/level/create",
  levelUpdate: "/level/update",
  levelDelete: "/level/delete/%s",
  getLevelupLogList: "/level/up/log?pageSize=%s&pageNum=%s&searchText=%s",
  levelupUpdate: "/level/up",
  getLevelUserSearchList:
    "/level/user/list?pageSize=%s&pageNum=%s&level=%s&searchText=%s",

  // wallet - 지갑
  feeWalletList: "/wallet/feeWallet/list?type=%s&deleted=%s&searchText=%s",
  feeWalletCreate: "/wallet/feeWallet/create",
  feewWalletUpdate: "/wallet/feeWallet/update",
  feeWalletDelete: "/wallet/feeWallet/delete/%s",
  getCoinBalance: "/wallet/coin/balance",
  getTransferRequestList:
    "/transaction/transferRequestList?pageSize=%s&pageNum=%s",
  getTransferLog:
    "/transaction/transferLogList?pageSize=%s&pageNum=%s&coinType=%s&requestType=%s&searchType=%s&startDate=%s&endDate=%s&searchText=%s&transferType=%s",
  downloadTransferLogExcel:
    "/transaction/transferLogList/excel?pageSize=%s&pageNum=%s&coinType=%s&requestType=%s&searchType=%s&startDate=%s&endDate=%s&searchText=%&transferType=%s",

  // coin - 코인
  distributeList:
    "/coin/airDropLogList?pageSize=%s&pageNum=%s&type=%s&searchText=%s",
  manualCreate: "/coin/manual/excel",
  manualSingleCreate: "/coin/manual/single",
  dailyLockSet: "/foundation/decentral/set/dailyLock",
  dailyLockSetExcel: "/foundation/decentral/set/dailyLock/excelUpload",
  getCoinAutoList: "/coin/auto/list",
  coinAutoUpdate: "/coin/auto/update",

  // foundation - 재단
  getFoundationCount: "/foundation/list/user/count?coinType=%s",
  foundationUserList:
    "/foundation/list/user?pageSize=%s&pageNum=%s&coinType=%s&orderByType=%s&searchText=%s",
  decentralFoundationListExcel:
    "/foundation/list/user/decentral/excel?pageSize=%s&pageNum=%s&coinType=%s&orderByType=%s&searchText=%s",
  getDailyLockList:
    "/foundation/decentral/get/daily/lock?coinType=%s&searchType=%s&searchText=%s",
  getDailyLockRequestList:
    "/foundation/decentral/list/dailyLockRequest?pageSize=%s&pageNum=%s&lockType=%s&statusType=%s&searchType=%s&searchText=%s",
  unDailyLock: "/foundation/decentral/undailyLock",
  getCoinUserList: "/foundation/decentral/search/user?coinType=%s&id=%s",
  getLockList:
    "/foundation/decentral/get/lock?coinType=%s&searchType=%s&searchText=%s",
  lockSet: "/foundation/decentral/set/lock",
  unLock: "/foundation/decentral/unLock",
  lockUpdate: "/foundation/lock/update",
  lockDelete: "/foundation/lock/delete",
  lockMuptiDelete: "/foundation/lockMultipleDelete",

  // community - 게시글
  getNoticeList:
    "/community/notice/list?pageSize=%s&pageNum=%s&deleted=%s&category=%s&searchText=%s",
  noticeCreate: "/community/notice/create",
  noticeUpdate: "/community/notice/update",
  noticeDelete: "/community/notice/delete/%s",
  getFaqList:
    "/community/faq/list?pageSize=%s&pageNum=%s&deleted=%s&searchText=%s",
  faqCreate: "/community/faq/create",
  faqUpdate: "/community/faq/update",
  faqDelete: "/community/faq/delete/%s",

  // url
  getUrlList: "/urlLink/list?pageSize=%s&pageNum=%s&searchText=%s",
  urlCreate: "/urlLink/create",
  urlUpdate: "/urlLink/update",
  urlDelete: "/urlLink/delete/%s",

  // system - 시스템
  adminList: "/system/admin/list?pageSize=%s&pageNum=%s&searchText=%s",
  adminCreate: "/system/admin/create",
  adminUpdate: "/system/admin/update",
  adminDelete: "/system/admin/delete/%s",
  getCoinAuthList: "/system/admin/authCoinDetail/%s",
  getAuthList: "/system/admin/authDetail/%s",
  authUpdate: "/system/admin/authUpdate",
  authCoinUpdate: "/system/admin/authCoinUpdate",

  // adminUserInfo - 내정보
  adminUserUpdate: "/myPage/update",
  otpUpdate: "/myPage/otp/change/key"
};

const imageType = ["image/jpg", "image/jpeg", "image/png", "image/bmp"];

export {
  serverUrl,
  httpExec,
  makeUrl,
  httpGet,
  httpUrl,
  httpPut,
  httpPost,
  httpDownload,
  imageType
};
