import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

import "./App.css";
import Layout from "./components/Layout";
import { NotFound, Login, ChangePassword } from "./pages";

class App extends Component {
  componentDidMount() {
    const userInfo = reactLocalStorage.getObject("adminUser");
    let pathname = this.props.location.pathname.split("/");

    if (pathname[1] !== "changePassword") {
      if (!userInfo.idx && this.props.location.pathname !== "/") {
        alert("로그인이 필요합니다.");
        this.props.history.push("/");
      }
      if (userInfo.idx && this.props.location.pathname === "/") {
        let path =
          userInfo.authList[0].subMenu === null
            ? userInfo.authList[0].path
            : userInfo.authList[0].subMenu[0].subMenu === null
            ? userInfo.authList[0].subMenu[0].path
            : userInfo.authList[0].subMenu[0].subMenu[0].path;
        this.props.history.push(path);
      }
    }
  }

  render() {
    const { location } = this.props;
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/changePassword/:token" component={ChangePassword} />
        {location.pathname === "/404" ? (
          <Route component={NotFound} />
        ) : (
          <Route path="/:page" component={Layout} />
        )}
        {/* <Route path="/:page" component={Layout} /> */}
      </Switch>
    );
  }
}

export default withRouter(App);
