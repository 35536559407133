import React, { Component } from "react";

import { Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import { checkCoinName, amountFormat } from "../../util";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

class CoinBalance extends Component {
  state = {
    list: []
  };

  // 코인별 보유수량 조회 목록
  getCoinBalance = (params = {}) => {
    httpGet(httpUrl.getCoinBalance, [], {})
      .then((res) => {
        // console.log(JSON.stringify(res, null, 4));
        this.setState({ list: res.data });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getCoinBalance();
  }

  render() {
    const { list } = this.state;
    const columns = [
      {
        key: "coinType",
        title: "코인종류",
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: "수량 (+)",
        dataIndex: "plusBalance",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: "수량 (-)",
        dataIndex: "minusBalance",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      }
    ];

    return (
      <div>
        <div className="title">코인별 보유현황</div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.coinType}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
    );
  }
}
export default CoinBalance;
