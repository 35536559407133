import React, { Component } from "react";

import { Table, Input } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import {
  numberFormat,
  formatDate,
  amountFormat,
  checkCoinName,
  checkData
} from "../../util";
import string from "../../string";
import SelectBox from "../../components/common/SelectBox";
import stringTitle from "../../stringTitle";

const Search = Input.Search;
class Distribute extends Component {
  state = {
    list: [],
    pagination: {
      total: 0,
      current: 0
    },
    searchTypes: "ALL",
    searchText: ""
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getDistributeList({ pageNum: pager.current });
  };

  searchTypeHandleChange = (value) => {
    this.setState(
      {
        searchTypes: value
      },
      () => this.getDistributeList()
    );
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value
      },
      () => {
        this.getDistributeList();
      }
    );
  };

  // 배포 목록 조회
  getDistributeList = (params = {}) => {
    const { searchTypes, searchText } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.distributeList, [10, pageNum, searchTypes, searchText], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.coinAirDropLogList,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getDistributeList();
  }

  render() {
    const coinColumns = [
      {
        width: 140,
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        width: 140,
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        width: 140,
        title: "배포 종류",
        dataIndex: "type",
        className: "text-center",
        render: (data) => (
          <div>
            {string.distributeTypeString[data]
              ? string.distributeTypeString[data]
              : data}
          </div>
        )
      },
      {
        title: stringTitle.coinType + stringTitle.type,
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: stringTitle.price,
        dataIndex: "amount",
        className: "text-center",
        render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
      },
      {
        title: stringTitle.address,
        dataIndex: "address",
        className: "text-center"
      },
      {
        title: stringTitle.adminId,
        dataIndex: "adminId",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        width: 300,
        title: stringTitle.memo,
        dataIndex: "memo",
        className: "text-center",
        render: (data) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: 300
            }}
          >
            {checkData(data)}
          </div>
        )
      }
    ];

    return (
      <div>
        <div className="title">{stringTitle.coinDistribute}</div>
        <div className="wrapper mt-35 width-1240" />
        <div style={{ marginBottom: "10px", float: "right" }}>
          <label htmlFor="" className="m-r-10">
            대상{" "}
            <SelectBox
              value={string.distributeString[this.state.searchTypes]}
              code={string.distributeCode}
              codeString={string.distributeString}
              onChange={(value) => {
                if (value !== this.state.searchTypes)
                  this.searchTypeHandleChange(value);
              }}
              style={{ width: "160px" }}
            />
          </label>
          <label htmlFor="">
            {stringTitle.searchRequire}{" "}
            <Search
              placeholder="주소를 입력하세요"
              enterButton={stringTitle.search}
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </label>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          id="distribute-log-table"
          rowKey={(record) => record.idx}
          columns={coinColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default Distribute;
