import React, { Component } from "react";

import { Table, Button, Input, Checkbox } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { numberFormat, formatDate } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";

import DetailModal from "../../components/community/DetailModal";
import { showDeletePageNumConfirm } from "../../components/common/AlerInfoModal";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

const Search = Input.Search;
class Faq extends Component {
  state = {
    list: [],
    deleted: false,
    searchText: "",
    pagination: {
      total: 0,
      current: 0
    },

    isLoaded: false,

    createVisible: false,
    visible: false,
    detailData: {}
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    if (this.state.isLoaded) alert("조회버튼을 먼저 클릭해주세요.");
    else {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({ pagination: pager });

      this.getFaqList({ pageNum: pager.current });
    }
  };

  // 삭제 내역 포함 여부 설정
  deletedHandleChange = (e) => {
    this.setState({
      deleted: e.target.checked,
      isLoaded: true
    });
  };

  // 검색
  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        isLoaded: true
      },
      () => {
        this.getFaqList();
      }
    );
  };

  // 삭제
  onDelete = (value, row) => {
    const { list, deleted, pagination } = this.state;

    let pageNum =
      row.deleted === 0 && deleted === false
        ? list.length === 1
          ? pagination.current === 1
            ? pagination.current
            : pagination.current - 1
          : pagination.current
        : pagination.current;

    showDeletePageNumConfirm(
      `${row.title.find((item) => item.language === "ko").content}을(를) ${
        row.deleted ? stringTitle.restore : stringTitle.delete
      }하시겠습니까?`,
      httpUrl.faqDelete,
      row,
      this.getFaqList,
      pageNum
    );
  };

  getFaqList = (params = {}) => {
    const { deleted, searchText } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(httpUrl.getFaqList, [10, pageNum, deleted, searchText], {})
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.faqList,
          pagination,
          isLoaded: false
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getFaqList();
  }

  render() {
    const { visible, edit, detailData } = this.state;
    const faqColumns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center pointer",
        render: (data, row) => {
          return (
            <div
              onClick={() => {
                this.setState({
                  visible: true,
                  detailData: row,
                  edit: true
                });
              }}
            >
              {data[0].content}
            </div>
          );
        }
      },
      {
        title: stringTitle.status,
        dataIndex: "deleted",
        className: "text-center",
        render:
          // data => <div>{data === 0 ? '삭제' : '-'}</div>
          (data, row) => (
            <div>
              <SelectBox
                value={string.deleteString[data]}
                code={string.toggleCode}
                codeString={string.deleteString}
                onChange={(value) => {
                  if (value !== row.deleted) {
                    this.onDelete(value, row);
                  }
                }}
              />
            </div>
          )
      }
    ];

    return (
      <div>
        <div className="title">FAQ {stringTitle.management}</div>
        {visible && (
          <DetailModal
            edit={edit}
            type="faq"
            visible={visible}
            modalData={detailData}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            onOk={this.getFaqList}
          />
        )}
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({ visible: true, edit: false });
            }}
            style={{ marginBottom: "10px" }}
          >
            {stringTitle.create}
          </Button>
          <div className="flex-row flex-center flex-end">
            <label htmlFor="" className="m-r-10">
              {stringTitle.searchRequire}&nbsp;&nbsp;&nbsp;
              <Checkbox onChange={this.deletedHandleChange}>
                {stringTitle.deletedInclude}
              </Checkbox>
            </label>
            <Search
              placeholder={stringTitle.titlePlaceholder}
              enterButton={stringTitle.search}
              allowClear
              onSearch={this.onSearch}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={faqColumns}
          dataSource={this.state.list}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default Faq;
