import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import fileDownload from "js-file-download";

import { Table, Input, Select, Button, DatePicker, Icon, Modal } from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpDownload, httpGet, httpUrl } from "../../api/httpClient";

import {
  amountFormat,
  checkCoinName,
  checkData,
  endDateFormat,
  excelFormat,
  formatDate,
  numberFormat,
  startDateFormat
} from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";

const Option = Select.Option;
const { RangePicker } = DatePicker;
class TransferLog extends Component {
  state = {
    coinList: [
      {
        coinType: 0,
        name: stringTitle.all
      },
      ...reactLocalStorage.getObject("coinList")
    ],

    transferLog: [],
    pagination: {
      current: 0,
      total: 0
    },
    coinType: 0,
    requestType: "ALL",
    //searchType: ALL일 경우 searchText: null
    searchType: "ALL",
    transferType: "ALL",
    startDate: "",
    endDate: "",
    searchText: "",
    isLoaded: false,

    p2pVisible: false,
    logIdx: 0
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getTransferLog({ pageNum: pager.current });
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value,
      isLoaded: true
    });
  };

  // 검색 날짜 선택
  handleChangeDate = (value) => {
    this.setState({
      startDate: value.length !== 0 ? startDateFormat(value[0]) : "",
      endDate: value.length !== 0 ? endDateFormat(value[1]) : ""
    });
    // console.log(JSON.stringify(value, null, 4));
  };

  // 요청 조건 선택
  handleChangeRequestType = (value) => {
    this.setState({
      requestType: value
    });
  };

  // 전송 방법 선택
  handleChangeTransferType = (value) => {
    this.setState({
      transferType: value
    });
  };

  // 검색 조건 선택
  handleChangeSearchType = (value) => {
    let searchText = "";
    if (value !== "ALL") {
      searchText = this.state.searchText;
    }
    this.setState({
      searchType: value,
      searchText
    });
  };

  // 검색어 입력
  handleChangeSearchText = (e) => {
    this.setState({
      searchText: e.target.value
    });
  };

  // 엑셀 다운로드
  downloadExcel = () => {
    let {
      pagination,
      coinType,
      requestType,
      searchType,
      startDate,
      endDate,
      searchText,
      transferType
    } = this.state;

    if (!startDate || !endDate) {
      alert("검색 기간 날짜를 선택해주세요.");
    } else {
      let total = pagination.total <= 9 ? 10 : pagination.total;

      if (global.confirm("현재 검색 조건으로 다운로드 하시겠습니까?")) {
        httpDownload(
          httpUrl.downloadTransferLogExcel,
          [
            total,
            1,
            coinType,
            requestType,
            searchType,
            startDate,
            endDate,
            searchText,
            transferType
          ],
          {}
        )
          .then((res) => {
            fileDownload(
              res,
              `${excelFormat(startDate)} ~ ${excelFormat(
                endDate
              )} ${checkCoinName(coinType)} 거래현황.xlsx`
            );
          })
          .catch((error) => {
            Modal.error({
              title: stringTitle.downloadFail,
              content: error.response.data.message || error.message
            });
          });
      }
    }
  };

  getTransferLog = (params = {}) => {
    const {
      coinType,
      requestType,
      searchType,
      startDate,
      endDate,
      searchText,
      transferType
    } = this.state;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getTransferLog,
      [
        10,
        pageNum,
        coinType,
        requestType,
        searchType,
        startDate,
        endDate,
        searchText,
        transferType
      ],
      {}
    )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          transferLog: res.data.list === null ? [] : res.data.list,
          pagination,
          isLoaded: false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getTransferLog();
  }

  render() {
    const { transferLog } = this.state;

    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.transferDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.coinType + stringTitle.type,
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: stringTitle.send + stringTitle.type,
        dataIndex: "type",
        className: "text-center",
        render: (data) => (
          <div>
            {string.transferString[data]
              ? string.transferString[data]
              : stringTitle.etc}
          </div>
        )
      },
      {
        title: stringTitle.from,
        dataIndex: "from",
        className: "text-center",
        render: (data, row) => (
          <div>
            <b> {checkData(row.fromId)}</b>
            <br />
            {data}
          </div>
        )
      },
      {
        title: stringTitle.to,
        dataIndex: "to",
        className: "text-center",
        render: (data, row) => (
          <div>
            <b> {checkData(row.toId)}</b>
            <br />
            {data}
          </div>
        )
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      }
    ];

    const expandedRowRender = (record) => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: "txid",
          dataIndex: "txid",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },
        {
          title: stringTitle.amount,
          dataIndex: "amount",
          className: "text-center",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: stringTitle.fee,
          dataIndex: "fee",
          className: "text-center",
          render: (data, row) => <div>{amountFormat(data, row.coinType)}</div>
        },
        {
          title: stringTitle.memo,
          dataIndex: "memo",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },
        record.coinType === 3
          ? {
              title: "destinationTag",
              key: "destinationTag",
              dataIndex: "destinationTag",
              className: "text-center"
            }
          : {
              colSpan: 0,
              width: 0,
              render: () => {
                return {
                  props: {
                    colSpan: 0
                  }
                };
              }
            }
      ];

      return (
        <Table
          rowKey={(record) => record.txid}
          columns={dropColumns}
          dataSource={dataArr}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div className="title">
          {stringTitle.transfer} {stringTitle.situation}
        </div>
        <div className="m-b-10 flex-row flex-sb">
          <div className="flex-row flex-center">
            <label className="m-r-10">
              {stringTitle.coinType} {stringTitle.type}&nbsp;
              <Select
                style={{ width: "160px", marginRight: "10px" }}
                value={this.state.coinType}
                onChange={this.handleChangeCoinType}
              >
                {this.state.coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
            </label>
            <label className="m-r-10">
              {stringTitle.status} {stringTitle.type}&nbsp;
              <SelectBox
                code={string.requestLogCode}
                codeString={string.requestLogString}
                value={string.requestLogString[this.state.requestType]}
                onChange={(value) => {
                  this.handleChangeRequestType(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
            <label>
              {stringTitle.send} {stringTitle.type}&nbsp;
              <SelectBox
                codeString={string.transferString}
                value={string.transferString[this.state.transferType]}
                onChange={(value) => {
                  this.handleChangeTransferType(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
          </div>
          <div className="flex-row flex-center">
            <label className="m-r-10">
              {stringTitle.searchRequire}&nbsp;
              <SelectBox
                code={string.walletLogCode}
                codeString={string.transferSearchString}
                value={string.transferSearchString[this.state.searchType]}
                onChange={(value) => {
                  this.handleChangeSearchType(value);
                }}
                style={{ width: "160px" }}
              />
            </label>
            <Input
              placeholder="주소를 입력해주세요."
              disabled={this.state.searchType === "ALL"}
              value={this.state.searchText}
              onChange={this.handleChangeSearchText}
              style={{ width: "240px" }}
            />
            <Button
              onClick={() => {
                this.getTransferLog();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <div className="flex-row flex-center flex-sb m-b-10">
          <div className="flex-row flex-center">
            <label>
              {stringTitle.searchDate}&nbsp;
              <RangePicker
                locale={locale}
                onChange={(date) => {
                  this.handleChangeDate(date);
                }}
              />
            </label>
          </div>
          <Button
            onClick={this.downloadExcel}
            disabled={transferLog.length === 0 ? true : false}
          >
            <Icon type="download" style={{ fontSize: "20px" }} />
            {stringTitle.excelDownload}
          </Button>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={transferLog}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default TransferLog;
