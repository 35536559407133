import { Modal } from "antd";
import { httpPost, httpPut } from "../../api/httpClient";
import stringTitle from "../../stringTitle";
import {
  showCloseAlert,
  showCloseRefreshAlert,
  showPageNumAlert,
  showRefreshAlert
} from "./AlertModal";
import { ShowErrorAlert } from "./ErrorAlertModal";

const { confirm } = Modal;
// 생성 - create
export function showCreateConfirm(title, url, data, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPost(url, [], data)
        .then((res) => {
          // console.log(`res = ${JSON.stringify(res, null, 4)}`);
          if (res.data.result === "SUCCESS") {
            showCloseRefreshAlert("create", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showCreateRefreshConfirm(title, url, data, refresh, close) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPost(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseRefreshAlert("create", refresh, close);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
// update
export function showUpdateRefreshConfirm(title, url, data, refresh, alertType) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showRefreshAlert(alertType, refresh);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showUpdateRefreshCloseConfirm(
  title,
  url,
  data,
  refresh,
  onCancel,
  alertType
) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseRefreshAlert(alertType, refresh, onCancel);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showUpdateCloseConfirm(title, url, data, onCancel, alertType) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [], data)
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showCloseAlert(alertType, onCancel);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}

// delete - 삭제
export function showDeleteIdxConfirm(title, url, data, refresh) {
  confirm({
    width: 500,
    title: title,
    okText: data.deleted ? stringTitle.restore : stringTitle.delete,
    onOk() {
      httpPut(url, [data.idx], {})
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            data.deleted
              ? showRefreshAlert("restore", refresh)
              : showRefreshAlert("delete", refresh);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showDeletePageNumConfirm(title, url, data, refresh, pageNum) {
  confirm({
    width: 500,
    title: title,
    okText: data.deleted ? stringTitle.restore : stringTitle.delete,
    onOk() {
      httpPut(url, [data.idx], {})
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            data.deleted
              ? showPageNumAlert("restore", refresh, pageNum)
              : showPageNumAlert("delete", refresh, pageNum);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
export function showDeleteIdxPageNumConfirm(title, url, idx, refresh, pageNum) {
  confirm({
    width: 500,
    title: title,
    okText: stringTitle.check,
    onOk() {
      httpPut(url, [idx], {})
        .then((res) => {
          if (res.data.result === "SUCCESS") {
            showPageNumAlert("delete", refresh, pageNum);
          } else ShowErrorAlert(res.data.data);
        })
        .catch((error) => {
          ShowErrorAlert(error.response.data.message || error.message);
        });
    },
    cancelText: stringTitle.cancel,
    onCancel() {}
  });
}
