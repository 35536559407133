import React, { Component } from "react";
import { Tag } from "antd";

import stringTitle from "../../stringTitle";
import { checkCoinName, coinDivideFormat, numberFormat } from "../../util";

class CoinTags extends Component {
  render() {
    const { list } = this.props;
    return list.map((item, index) => (
      <Tag
        key={index}
        closable
        onClose={() => {
          list.splice(index, 1);
        }}
      >
        {stringTitle.coinType}&nbsp;:&nbsp;{checkCoinName(item.coinType)}
        <br />
        {stringTitle.amount}&nbsp;:&nbsp;
        {numberFormat(coinDivideFormat(item.amount, item.coinType))}
      </Tag>
    ));
  }
}

export default CoinTags;
