import React, { Component } from "react";
import { Select } from "antd";

const Option = Select.Option;
class LevelSelectBox extends Component {
  state = {
    dataType: this.props.codeType
  };

  renderOption = () => {
    const { codeString } = this.props;
    let result = [];

    for (let i = 0; i < codeString.length; i++) {
      result.push(
        <Option key={`${codeString[i].level}`} value={codeString[i].level}>
          {codeString[i].name}
        </Option>
      );
    }

    return result;
  };

  render() {
    const { style, value, onChange } = this.props;
    return (
      <Select
        {...this.props}
        style={{ width: "120px", ...style }}
        value={value}
        onChange={(value) => {
          onChange(value);
        }}
      >
        {this.renderOption()}
      </Select>
    );
  }
}

export default LevelSelectBox;
