import React, { Component } from "react";

import { Modal, Input, Checkbox, Form, Button, Row, Col } from "antd";

import { httpUrl } from "../../api/httpClient";

import { formatDate, numberFormat } from "../../util";
import Const from "./../../const";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";

import PreviewModal from "./PreviewModal";
import {
  showCreateConfirm,
  showUpdateRefreshCloseConfirm
} from "../common/AlerInfoModal";

const FormItem = Form.Item;
const DetailModal = Form.create()(
  class extends Component {
    state = {
      data: {
        title: "",
        content: "",
        checked: false
      }
    };

    onSelect = (value, type, language) => {
      var data = Object.assign({}, this.state.data);

      data[type] = value;
      this.setState({
        data
      });
      // console.log(JSON.stringify(data, null, 4));
    };

    // 공지사항 / FAQ 생성
    onSubmit = (e) => {
      const { type, form } = this.props;

      let url = "";

      e.preventDefault();
      form.validateFields((err, values) => {
        let createData = {
          ...values,
          title: [
            {
              idx: 0,
              content: values.title,
              language: "ko"
            }
          ],
          content: [
            {
              idx: 0,
              content: values.content,
              language: "ko"
            }
          ]
        };

        if (type === "faq") url = httpUrl.faqCreate;
        if (type === "notice") {
          url = httpUrl.noticeCreate;
          createData = { ...createData, main: this.state.data.checked ? 1 : 0 };
        }
        console.log(JSON.stringify(createData, null, 4));

        if (!err) {
          showCreateConfirm(
            stringTitle.createQuest,
            url,
            createData,
            this.props.onOk,
            this.onCancel
          );
        }
      });
    };

    // 공지사항 / FAQ 수정
    onUpdate = (e) => {
      const { type, form, modalData } = this.props;

      let url = "";

      e.preventDefault();
      form.validateFields((err, values) => {
        let updateData = {
          ...modalData,
          ...values,
          title: [
            {
              idx: modalData.title[0].idx,
              content: values.title,
              language: "ko"
            }
          ],
          content: [
            {
              idx: modalData.content[0].idx,
              content: values.content,
              language: "ko"
            }
          ]
        };

        if (type === "faq") url = httpUrl.faqUpdate;
        if (type === "notice") {
          url = httpUrl.noticeUpdate;
          updateData = { ...updateData, main: this.state.data.checked ? 1 : 0 };
        }
        // console.log(JSON.stringify(updateData, null, 4));

        if (!err)
          showUpdateRefreshCloseConfirm(
            stringTitle.updateQuest,
            url,
            updateData,
            this.props.onOk,
            this.props.onCancel,
            "update"
          );
      });
    };

    onCancel = () => {
      this.props.onCancel();
      this.props.form.resetFields();
    };

    componentDidMount() {
      const { modalData, edit, form } = this.props;
      // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);

      if (edit) {
        form.setFieldsValue({
          ...modalData,
          title: modalData.title[0].content,
          content: modalData.content[0].content
        });

        this.setState({
          data: {
            ...modalData,
            checked: parseInt(modalData.main) === 1 ? true : false
          }
        });
      }
    }

    render() {
      const { data, preview, previewData } = this.state;
      const { visible, type, form, edit } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 17 }
        }
      };

      return (
        <Modal
          visible={visible}
          title={type === "notice" ? "공지사항" : "FAQ"}
          okText={edit ? stringTitle.update : stringTitle.create}
          cancelText={stringTitle.cancel}
          onOk={edit ? this.onUpdate : this.onSubmit}
          onCancel={this.onCancel}
          style={{ minWidth: "700px" }}
          maskClosable={false}
        >
          {/* <div>{JSON.stringify(modalData)}</div> */}
          <Form {...formItemLayout} onSubmit={this.onRegistry}>
            {edit && (
              <Row
                className="flex-row flex-center flex-sb "
                style={{
                  paddingLeft: "80px",
                  paddingRight: 60,
                  color: "black",
                  marginBottom: "20px"
                }}
              >
                <Col span={18}>
                  {stringTitle.createDate}&nbsp;:&nbsp;&nbsp;
                  {formatDate(data.createDate)}
                </Col>
                <Col span={6} className="flex-end text-right">
                  {stringTitle.read}&nbsp;:&nbsp;&nbsp;
                  {numberFormat(data.read)}
                </Col>
              </Row>
            )}
            {type === "notice" && (
              <FormItem label={<span>{stringTitle.division}&nbsp;</span>}>
                {getFieldDecorator("category", {
                  rules: [
                    {
                      required: true,
                      message: "카테고리를 선택해주세요!"
                    }
                  ]
                })(
                  <SelectBox
                    type="category"
                    placeholder={stringTitle.defaultPlaceholder}
                    code={string.noticeSelectCode}
                    codeString={string.noticeSelect}
                    style={{ width: "200px" }}
                    disable={edit ? true : false}
                  />
                )}
                <Checkbox
                  style={{ float: "right" }}
                  defaultChecked={data.checked}
                  value={data.checked}
                  onChange={(e) => {
                    this.setState({
                      data: { ...data, checked: e.target.checked }
                    });
                  }}
                >
                  {stringTitle.mainCheck}
                </Checkbox>
              </FormItem>
            )}
            <FormItem label={<span>{stringTitle.title}&nbsp;</span>}>
              {getFieldDecorator("title", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: "제목을 입력해주세요!"
                  }
                ]
              })(<Input allowClear />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.content}&nbsp;</span>}>
              {getFieldDecorator("content", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: "내용을 입력해주세요!"
                  }
                ]
              })(<Input.TextArea rows={8} />)}
            </FormItem>
            {Const.isPreviewFlag && type === "notice" && (
              <FormItem colon={false} label={<span>&nbsp;</span>}>
                <Button
                  // disabled={
                  //   data.content.find((item) => item.language === "ko").content !== "" ? false : true
                  // }
                  onClick={() => {
                    if (data.content !== "")
                      this.setState({
                        preview: true,
                        previewData: form.getFieldValue("content")
                      });
                  }}
                >
                  {stringTitle.preView}
                </Button>
              </FormItem>
            )}

            {preview && (
              <PreviewModal
                visible={preview}
                data={previewData}
                onCancel={() => {
                  this.setState({
                    preview: false
                  });
                }}
              />
            )}
          </Form>
        </Modal>
      );
    }
  }
);

export default DetailModal;
