import React, { Component } from "react";

import { Button, Input, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";

import { checkData, numberFormat } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import { showDeleteIdxConfirm } from "../../components/common/AlerInfoModal";
import UrlModal from "../../components/url/UrlModal";

class UrlList extends Component {
  state = {
    list: [],
    pagination: { total: 0, current: 0 },
    searchText: "",

    visible: false,
    selectData: {}
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getUrlList({ pageNum: pager.current });
  };

  // 검색
  searchTextHandleChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  onDelete = (data) => {
    showDeleteIdxConfirm(
      data.deleted ? stringTitle.restoreQuest : stringTitle.deleteQuest,
      httpUrl.urlDelete,
      data,
      this.getUrlList
    );
  };

  getUrlList = (params = {}) => {
    const { searchText } = this.state;
    const pageNum = params.pageNum || 1;

    httpGet(httpUrl.getUrlList, [10, pageNum, searchText], {})
      .then((res) => {
        // console.log(`url res => ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.linkList,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getUrlList();
  }

  render() {
    const { visible, page, selectData, list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.url,
        dataIndex: "url",
        className: "text-center",
        render: (data, row) => (
          <div
            className="pointer"
            onClick={() => {
              this.setState({
                visible: true,
                selectData: row,
                page: "edit"
              });
            }}
          >
            {checkData(data)}
          </div>
        )
      },
      {
        title: stringTitle.title,
        dataIndex: "title",
        className: "text-center",
        render: (data) => data[0].content
      },
      {
        title: stringTitle.content,
        dataIndex: "content",
        className: "text-center",
        render: (data) => data[0].content
      },
      {
        title: stringTitle.status,
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <SelectBox
            value={string.deleteString[data]}
            code={string.toggleCode}
            codeString={string.deleteString}
            onChange={(value) => {
              if (parseInt(value) !== row.deleted) {
                this.onDelete(row);
              }
            }}
          />
        )
      }
    ];

    return (
      <div>
        {visible && (
          <UrlModal
            page={page}
            visible={visible}
            data={selectData}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            refresh={this.getUrlList}
          />
        )}
        <div className="title">
          {stringTitle.url} {stringTitle.management}
        </div>
        <div style={{ clear: "both" }} />
        <div className="flex-row flex-center flex-sb m-b-10">
          <Button
            onClick={() => {
              this.setState({
                visible: true,
                page: "create"
              });
            }}
          >
            {stringTitle.create}
          </Button>
          <div className="flex-row flex-center flex-end">
            <Input
              style={{ width: 250, marginRight: 10 }}
              placeholder="검색어를 입력하세요"
              onChange={(e) => {
                this.searchTextHandleChange(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                this.getUrlList();
              }}
            >
              {stringTitle.search}
            </Button>
          </div>
        </div>
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default UrlList;
