import React, { Component } from "react";

import { Button, Input, Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";
import { formatDate, numberFormat } from "../../util";
import { reactLocalStorage } from "reactjs-localstorage";
import string from "../../string";
import { showUpdateRefreshConfirm } from "../common/AlerInfoModal";

class CompetitionRequset extends Component {
  state = {
    list: [],
    pagination: { current: 0, total: 0 },
    status: "REQUEST",
    searchText: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getCompetitionRequestList({ pageNum: pager.current });
  };

  // 검색 대상 설정
  statusTypeHandleChange = (value) => {
    this.setState({
      status: value
    });
  };

  // 검색
  searchTextHandleChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  onStatusUpdate = (data, status) => {
    let updateData = { idx: data.idx, status };
    // console.log(`updateData: ${JSON.stringify(updateData, null, 4)}`);
    showUpdateRefreshConfirm(
      status === "ACCEPT" ? stringTitle.acceptQuest : stringTitle.rejectQuest,
      httpUrl.competitionRequsetStatusUpdate,
      updateData,
      this.refresh,
      status === "ACCEPT" ? "accept" : "reject"
    );
  };

  refresh = () => {
    this.getCompetitionRequestList();
    this.props.refresh();
  };

  getCompetitionRequestList = (params = {}) => {
    const { refIdx } = this.props;
    const { status, searchText } = this.state;

    let pageNum = params.pageNum || 1;
    if (params.refresh) pageNum = this.state.pagination.current;

    httpGet(
      httpUrl.getCompetitionRequestList,
      [10, pageNum, refIdx, status, searchText],
      {}
    )
      .then((res) => {
        // console.log(`competition request = ${JSON.stringify(res, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getCompetitionRequestList();
  }

  render() {
    const levelList = reactLocalStorage.getObject("levelList");
    const { visible, onCancel } = this.props;
    const { status, list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.id,
        dataIndex: "id",
        className: "text-center"
      },
      {
        title: stringTitle.name,
        dataIndex: "name",
        className: "text-center"
      },
      {
        title: stringTitle.joinLevel,
        dataIndex: "level",
        className: "text-center",
        render: (data, row) =>
          levelList.find((item) => item.level === data) &&
          levelList.find((item) => item.level === data).name
      },
      {
        title: stringTitle.address,
        dataIndex: "address",
        className: "text-center"
        // render: (data, row) =>
      },
      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data, row) =>
          data === "REQUEST" ? (
            <div>
              <Button
                className="m-r-10"
                onClick={() => {
                  this.onStatusUpdate(row, "ACCEPT");
                }}
              >
                {string.competitionRequestString["ACCEPT"]}
              </Button>
              <Button
                onClick={() => {
                  this.onStatusUpdate(row, "REJECT");
                }}
              >
                {string.competitionRequestString["REJECT"]}
              </Button>
            </div>
          ) : (
            string.competitionRequestString[data]
          )
      }
    ];

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={false}
        title="신청현황"
        style={{ minWidth: "1400px" }}
        maskClosable={false}
      >
        <div className="flex-row flex-center flex-end m-b-10">
          <SelectBox
            codeString={string.competitionRequestString}
            value={status}
            onChange={(value) => {
              if (value !== status) {
                this.statusTypeHandleChange(value);
              }
            }}
          />
          <Input
            allowClear
            style={{ width: 300, marginLeft: 10, marginRight: 10 }}
            onChange={(e) => {
              this.searchTextHandleChange(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              this.getCompetitionRequestList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default CompetitionRequset;
