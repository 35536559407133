import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import {
  Admin,
  Auto,
  CoinBalance,
  CompetitionList,
  DecentralDailyLock,
  DecentralFoundationList,
  Distribute,
  Faq,
  FeeWallet,
  LevelList,
  LevelupLogList,
  LockManual,
  Manual,
  MemberList,
  MyInfo,
  Notice,
  TransferLog,
  UrlList,
  WithDrawRequestLog
} from "../pages";

export default class Content extends Component {
  render() {
    return (
      <Layout.Content style={{ margin: "24px 16px 0" }}>
        <div
          style={{
            padding: 24,
            background: "#fff",
            minHeight: "82vh",
            minWidth: "1280px"
          }}
        >
          <Switch>
            {/* 대회 관리 */}
            <Route path="/competition/list" component={CompetitionList} />
            {/* 회원 */}
            <Route exact path="/user/memberList" component={MemberList} />
            {/* 등급 */}
            <Route exact path="/level/list" component={LevelList} />
            <Route exact path="/levelUp/log" component={LevelupLogList} />
            {/* 지갑 */}
            <Route path="/wallet/feeWallet" component={FeeWallet} />
            <Route path="/wallet/coinBalance" component={CoinBalance} />
            <Route
              path="/transaction/withDrawRequestLog"
              component={WithDrawRequestLog}
            />
            <Route path="/transaction/transferLog" component={TransferLog} />
            {/* 재단 */}
            <Route
              path="/decentral/foundationList"
              component={DecentralFoundationList}
            />
            <Route path="/decentral/dailyLock" component={DecentralDailyLock} />
            {/* 코인 */}
            <Route path="/coin/distribute" component={Distribute} />
            <Route path="/coin/manual" component={Manual} />
            <Route path="/coin/lockManual" component={LockManual} />
            <Route path="/coin/auto" component={Auto} />
            {/* 게시글 */}
            <Route path="/community/notice" component={Notice} />
            <Route path="/community/faq" component={Faq} />
            {/* url */}
            <Route path="/urlLink/list" component={UrlList} />
            {/* 시스템 */}
            <Route path="/system/admin" component={Admin} />
            {/* 정보수정 */}
            <Route path="/mypage/myinfo" component={MyInfo} />
          </Switch>
        </div>
      </Layout.Content>
    );
  }
}
