import React, { Component } from "react";

import { Button, Input, Modal, Table } from "antd";

import { httpGet, httpUrl } from "../../api/httpClient";
import { checkLevelName, numberFormat } from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";
import UserInfo from "../user/UserInfo";

class LevelUserSearchModal extends Component {
  state = {
    list: [],
    pagination: { total: 0, current: 0 },
    searchText: ""
  };

  // 페이징 처리
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({ pagination: pager });

    this.getLevelUserSearchList({ pageNum: pager.current });
  };

  // 검색
  searchTextHandelChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  // 회원 정보 리스트
  getLevelUserSearchList = (params = {}) => {
    const { searchText } = this.state;
    const { level } = this.props;
    let pageNum = params.pageNum || 1;

    httpGet(
      httpUrl.getLevelUserSearchList,
      [10, pageNum, level, searchText],
      {}
    )
      .then((res) => {
        // console.log(`data= ${JSON.stringify(res.data, null, 4)}`);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.list === null ? [] : res.data.list,
          pagination
        });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getLevelUserSearchList();
  }

  render() {
    const { visible, onCancel, onSelect } = this.props;
    const { list, pagination } = this.state;
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "user.idx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: stringTitle.level,
        dataIndex: "user.level",
        className: "text-center",
        render: (data) => checkLevelName(data)
      },
      {
        title: stringTitle.user,
        dataIndex: "user",
        className: "text-center",
        render: (data) => <UserInfo data={data} />
      },
      {
        dataIndex: "",
        className: "text-center",
        render: (data, row) => (
          <Button
            onClick={() => {
              onSelect(row);
              onCancel();
            }}
          >
            {stringTitle.select}
          </Button>
        )
      }
    ];

    return (
      <Modal
        visible={visible}
        title={stringTitle.user + stringTitle.search}
        onCancel={onCancel}
        style={{ minWidth: "800px" }}
        maskClosable={false}
        footer={false}
      >
        <div className={"flex-row flex-center flex-end m-b-10"}>
          <Input
            style={{ width: 200, marginRight: 10 }}
            placeholder="아이디 / 이름"
            allowClear
            onChange={(e) => {
              this.searchTextHandelChange(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              this.getLevelUserSearchList();
            }}
          >
            {stringTitle.search}
          </Button>
        </div>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.user.idx}
          onRow={(row) => ({
            // console.log(`row = ${JSON.stringify(row, null, 4)}`);
            onClick: () => {
              onSelect(row);
              onCancel();
            }
          })}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </Modal>
    );
  }
}

export default LevelUserSearchModal;
