import React, { Component } from "react";

import {
  Modal,
  Upload,
  Form,
  Button,
  Icon,
  Input,
  Row,
  Col,
  InputNumber,
  DatePicker
} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import { httpUrl, serverUrl, httpPost } from "../../api/httpClient";
import { coinMultiFormat, formatDateSecond } from "../../util";

import stringTitle from "../../stringTitle";
import CoinSelectBox from "../../components/common/CoinSelectBox";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";
import ManualInfo from "./ManualInfo";

const { confirm } = Modal;
const FormItem = Form.Item;
const LockManual = Form.create()(
  class extends Component {
    state = {
      coinType: 0
    };

    // 코인 종류 선택
    coinTypeHandleChange = (value) => {
      this.setState({
        coinType: value
      });
    };

    // 배포
    onSubmit = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        // console.log(`err = ${JSON.stringify(values, null, 4)}`);
        if (!err) {
          let body = {
            ...values,
            amount: coinMultiFormat(values.amount, values.coinType),
            releaseDate: formatDateSecond(values.releaseDate),
            transferFlag: true
          };
          confirm({
            width: 500,
            title: stringTitle.coinType + stringTitle.sendQuest,
            okText: stringTitle.check,
            onOk() {
              httpPost(httpUrl.dailyLockSet, [], body)
                .then((res) => {
                  if (res.data.result === "SUCCESS") {
                    Modal.info({
                      title: stringTitle.sendAnw,
                      okText: stringTitle.check,
                      onOk() {
                        this.props.form.resetFields();
                        this.setState({
                          coinType: 0
                        });
                      }
                    });
                  } else ShowErrorAlert(res.data.data);
                })
                .catch((error) => {
                  ShowErrorAlert(error.response.data.message || error.message);
                });
            },
            cancelText: stringTitle.cancel,
            onCancel() {}
          });
          // console.log(`createData = ${JSON.stringify(createData, null, 4)}`);
        }
      });
    };

    render() {
      const { coinType } = this.state;
      const { getFieldDecorator } = this.props.form;

      const props = {
        action: serverUrl + httpUrl.dailyLockSetExcel,
        multiple: false,
        withCredentials: true,
        beforeUpload: (file) => {
          if (global.confirm(stringTitle.sendQuest)) {
            return true;
          } else {
            return false;
          }
        },
        onSuccess: (file) => {
          var success = file.data.success;
          var fail = file.data.fail;
          var total = success + fail;

          var s = `${stringTitle.total} : ${total}, ${stringTitle.success} : ${success}, ${stringTitle.fail} : ${fail}`;
          Modal.info({
            title: stringTitle.distribute + stringTitle.result,
            content: (
              <div>
                <p>
                  {file.data.failAddresses.length > 0 ? (
                    <div>
                      <div>{`${s}`}</div>
                      <br />
                      {`- ${stringTitle.fail} ${stringTitle.result}\n`}
                      {file.data.failAddresses.length > 0 &&
                        file.data.failAddresses.map((value) => (
                          <div>{value}</div>
                        ))}
                    </div>
                  ) : (
                    <div>{s}</div>
                  )}
                </p>
              </div>
            ),
            onOk() {}
          });
          // if (file.data.failAddresses.length > 0) {
          //   file.data.failAddresses = file.data.failAddresses.map(
          //     value => "\n" + value
          //   );
          //   s = s + "- 실패한 Card" + file.data.failAddresses;
          // }
          // alert(s);
        },
        onError(err) {
          Modal.error({
            title: stringTitle.distribute + stringTitle.result,
            content: stringTitle.fileUploadFail
          });
        }
      };

      const formItemLayout = {
        labelCol: {
          xs: { span: 8 },
          sm: { span: 8 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0
          },
          sm: {
            span: 16,
            offset: 8
          }
        }
      };

      return (
        <div>
          <div
            style={{
              fontSize: "20px",
              color: "#000",
              textAlign: "center",
              marginBottom: "30px",
              fontWeight: "bold"
            }}
          >
            LOCK {stringTitle.distribute}
          </div>
          <div className="wrapper mt-35 width-1240" />

          <div
            style={{
              maxWidth: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: 0,
              marginBottom: 0,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Form {...formItemLayout} onSubmit={this.onSubmit}>
              <FormItem label={<span></span>} colon={false}>
                <Upload {...props} accept=".xlsx, .xls" showUploadList={false}>
                  <Button style={{ marginRight: "20px" }}>
                    <Icon type="upload" style={{ fontSize: "20px" }} />{" "}
                    {stringTitle.fileUpload}
                  </Button>
                </Upload>
                <a href={"/setDailyLockExcelFomat.xlsx"} download>
                  <Button>
                    <Icon type="download" style={{ fontSize: "20px" }} />
                    {stringTitle.formDownload}
                  </Button>
                </a>
                <span style={{ color: "blue", marginLeft: "10px" }}>
                  {stringTitle.multiUploadInfo}
                </span>
              </FormItem>
              {/* <FormItem label={<span></span>} colon={false}></FormItem> */}
              <div
                className="flex-row flex-center justify-center"
                style={{ color: "blue" }}
              >
                {stringTitle.coinLockInfo}
              </div>
              <FormItem
                label={
                  <span>
                    {stringTitle.coinType} {stringTitle.type}&nbsp;
                  </span>
                }
              >
                <Row>
                  <Col span={6}>
                    {getFieldDecorator("coinType", {
                      rules: [
                        {
                          required: true,
                          message: stringTitle.coinTypePlaceholder
                        }
                      ]
                    })(
                      <CoinSelectBox
                        onChange={this.coinTypeHandleChange}
                        placeholder={stringTitle.defaultPlaceholder}
                        style={{ width: "160px" }}
                      />
                    )}
                  </Col>
                  <Col span={18}>
                    {coinType === 3 && (
                      <FormItem style={{ marginBottom: 0 }}>
                        {getFieldDecorator("destinationTag", {
                          rules: [
                            {
                              required: true,
                              message: "destinationTag를 입력해주세요."
                            }
                          ]
                        })(
                          <Input
                            allowClear={true}
                            placeholder="destinationTag"
                            style={{ width: "300px" }}
                          />
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
              </FormItem>
              <FormItem label={<span>{stringTitle.address}&nbsp;</span>}>
                {getFieldDecorator("address", {
                  rules: [
                    {
                      required: true,
                      message: stringTitle.addressPlaceholder
                    }
                  ]
                })(
                  <Input
                    allowClear={true}
                    style={{ width: "360px" }}
                    placeholder={stringTitle.addressPlaceholder}
                  />
                )}
              </FormItem>
              <FormItem label={<span>{stringTitle.amount}&nbsp;</span>}>
                {getFieldDecorator("amount", {
                  rules: [
                    {
                      required: true,
                      message: stringTitle.amountPlaceholder
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (value && coinType === 0) {
                          cb(stringTitle.coinTypePlaceholder);
                        }
                        cb();
                      }
                    }
                  ]
                })(
                  <InputNumber
                    allowClear
                    formatter={(value) =>
                      `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "300px" }}
                    placeholder={stringTitle.amountPlaceholder}
                  />
                )}
              </FormItem>
              <FormItem label={<span>{stringTitle.releaseDate}&nbsp;</span>}>
                {getFieldDecorator("releaseDate", {
                  rules: [
                    {
                      required: true,
                      message: stringTitle.releaseDatePlaceholder
                    }
                  ]
                })(
                  <DatePicker
                    style={{ width: "200px" }}
                    placeholder={stringTitle.releaseDatePlaceholder}
                    disabledDate={this.disabledDate}
                    locale={locale}
                  />
                )}
              </FormItem>
              <FormItem label={<span>{stringTitle.releaseDays}&nbsp;</span>}>
                {getFieldDecorator("releaseDays", {
                  rules: [
                    {
                      required: true,
                      message: stringTitle.releaseDaysPlaceholder
                    }
                  ]
                })(
                  <InputNumber
                    allowClear
                    style={{ width: "200px" }}
                    placeholder="10"
                  />
                )}
              </FormItem>
              <FormItem label={<span></span>} colon={false}>
                <ManualInfo />
              </FormItem>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  {stringTitle.distributeFlag}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      );
    }
  }
);

export default LockManual;
