import React, { Component } from "react";

import { Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";

import { formatDate, amountFormat, checkCoinName, checkData } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";

class WithDrawRequestLog extends Component {
  state = {
    withDrawList: [],
    pagination: {
      total: 0,
      current: 0
    }
  };

  // 페이징
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getWithDrawLog({
      pageNum: pager.current
    });
  };

  // 외부 출금 요청 내역 조회
  getWithDrawLog = (params = {}) => {
    let pageNum = params.pageNum || 1;

    httpGet(httpUrl.getTransferRequestList, [10, pageNum], {})
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          withDrawList: res.data.list,
          pagination
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    this.getWithDrawLog();
  }

  render() {
    const columns = [
      {
        key: "idx",
        title: stringTitle.idx,
        dataIndex: "idx",
        className: "text-center"
      },
      {
        title: stringTitle.coinType,
        dataIndex: "coinType",
        className: "text-center",
        render: (data) => <div>{checkCoinName(data)}</div>
      },
      {
        title: stringTitle.createDate,
        dataIndex: "createDate",
        className: "text-center",
        render: (data) => <div>{formatDate(data)}</div>
      },
      {
        title: stringTitle.from,
        dataIndex: "from",
        className: "text-center"
      },
      {
        title: stringTitle.to,
        dataIndex: "to",
        className: "text-center"
      },

      {
        title: stringTitle.status,
        dataIndex: "status",
        className: "text-center",
        render: (data) => <div>{string.requestLogString[data]}</div>
      },
      {
        title: stringTitle.delete + stringTitle.status,
        dataIndex: "deleted",
        className: "text-center",
        render: (data) => <div>{string.deleteString[data]}</div>
      }
    ];

    const expandedRowRender = (record) => {
      let dataArr = [record];
      const dropColumns = [
        {
          title: stringTitle.amount,
          key: "amount",
          dataIndex: "amount",
          className: "text-center",
          render: (data) => <div>{amountFormat(data, record.coinType)}</div>
        },
        {
          title: stringTitle.fee,
          dataIndex: "fee",
          className: "text-center",
          render: (data) => <div>{amountFormat(data, record.coinType)}</div>
        },
        {
          title: "trxId",
          dataIndex: "trxId",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },
        {
          title: "logIdx",
          dataIndex: "logIdx",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },

        {
          title: stringTitle.errorMsg,
          dataIndex: "errorMsg",
          className: "text-center",
          render: (data) => <div>{checkData(data)}</div>
        },
        record.coinType === 3
          ? {
              title: "destinationTag",
              dataIndex: "destinationTag",
              className: "text-center"
            }
          : {
              colSpan: 0,
              width: 0,
              render: () => {
                return {
                  props: {
                    colSpan: 0
                  }
                };
              }
            }
      ];

      return (
        <Table
          rowKey={(record) => record.idx}
          columns={dropColumns}
          dataSource={dataArr}
          pagination={false}
        />
      );
    };

    return (
      <div>
        <div className="title">출금요청내역</div>
        <Table
          id="table"
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={this.state.withDrawList}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
        />
      </div>
    );
  }
}

export default WithDrawRequestLog;
