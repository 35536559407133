import React, { Component } from "react";

import { Form, Input, InputNumber, Modal } from "antd";

import { httpUrl } from "../../api/httpClient";

import stringTitle from "../../stringTitle";
import { showCreateConfirm } from "../common/AlerInfoModal";

const FormItem = Form.Item;
const LevelModal = Form.create()(
  class extends Component {
    state = {
      language: "",
      nameContent: "",
      name: global.lanList.map((item) => {
        return { idx: 0, content: "", language: item };
      })
    };

    onChangeLanguage = (value) => {
      const { name } = this.state;
      let nameContent = "";
      if (name.find((item) => item.language === value)) {
        nameContent = name.find((item) => item.language === value).content;
      }

      this.setState({ language: value, nameContent });
    };

    onChangeName = (value, language) => {
      const { name } = this.state;

      if (name.find((item) => item.language === language)) {
        name.find((item) => item.language === language).content = value;
      }

      this.setState({ nameContent: value });
      this.props.form.setFieldsValue({ name });
    };

    onSumit = (e) => {
      const { form } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        let name = {
          idx: 0,
          content: values.name,
          language: "ko"
        };
        let body = {
          ...values,
          name: name
        };
        // console.log(`body  = ${JSON.stringify(body, null, 4)}`);
        showCreateConfirm(
          stringTitle.createQuest,
          httpUrl.levelCreate,
          body,
          this.props.refresh,
          this.props.onCancel
        );
      });
    };

    componentDidMount() {
      this.setState({ language: global.language });
      this.props.form.setFieldsValue({ language: global.language });
    }

    render() {
      const { visible, onCancel, form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          onOk={this.onSumit}
          onCancel={onCancel}
          maskClosable={false}
          title={stringTitle.levelup}
          okText={stringTitle.create}
          cancelText={stringTitle.cancel}
        >
          <Form {...formItemLayout}>
            {/* <FormItem label={<span>{stringTitle.language}&nbsp;</span>}>
              <Select
                style={{ width: "120px" }}
                value={language}
                onChange={(value, index) => {
                  this.onChangeLanguage(value, index);
                }}
              >
                {global.lanList.map((item, index) => {
                  return (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </FormItem> */}
            <FormItem label={<span>{stringTitle.name}&nbsp;</span>}>
              {getFieldDecorator("name", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: "등급을 입력해주세요!"
                  }
                ]
              })(
                <Input
                  style={{ width: 300 }}
                  // onChange={(e) => {
                  //   this.onChangeName(e.target.value, language);
                  // }}
                />
              )}
            </FormItem>
            <FormItem label={<span>{stringTitle.level}&nbsp;</span>}>
              {getFieldDecorator(`level`, {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: "등급을 입력해주세요!"
                  }
                ]
              })(<InputNumber min={0} style={{ width: 300 }} />)}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

export default LevelModal;
