import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Button, Modal, Select, Table } from "antd";
import moment from "moment";

import { httpGet, httpPost, httpUrl } from "../../api/httpClient";
import {
  checkData,
  coinDivideFormat,
  formatDateSecond,
  numberFormat,
  releaseTimeDate
} from "../../util";
import stringTitle from "../../stringTitle";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

const Option = Select.Option;
class LockListModal extends Component {
  state = {
    list: [],
    listPending: false,
    coinList: [],
    coinType: 0
  };

  // 코인 종류 선택
  handleChangeCoinType = (value) => {
    this.setState({
      coinType: value
    });
  };

  // LOCK 해지
  unLock = (row) => {
    const { coinType } = this.state;
    let body = { ...row, coinType };
    // console.log(`body =${JSON.stringify(body, null, 4)}`);
    Modal.confirm({
      title: stringTitle.unlockQuest,
      onOk() {
        httpPost(httpUrl.unLock, [], body)
          .then((res) => {
            // console.log(JSON.stringify(res, null, 4));
            if (res.data) {
              Modal.confirm({
                title: stringTitle.unlockAnw,
                onOk() {
                  this.getLockList();
                  if (this.props.page) this.props.refresh();
                }
              });
            } else ShowErrorAlert(res.data.data);
          })
          .catch((error) => {
            ShowErrorAlert(error.response.data.message || error.message);
          });
      }
    });
  };

  // LOCK 목록
  getLockList = () => {
    const { searchText } = this.props;
    const { coinType } = this.state;

    httpGet(httpUrl.getLockList, [coinType, "ID", searchText], {})
      .then((res) => {
        // console.log(`-- LOCK LIST --`);
        // console.log(`res =${JSON.stringify(res.data, null, 4)}`);
        let listData = [];
        res.data.list.forEach((item) => {
          if (item.pending !== undefined) listData.push(item);
          else listData.push({ ...item, pending: false });
        });
        // console.log(`listData =${JSON.stringify(listData, null, 4)}`);
        this.setState({
          list: listData.sort((x, y) => y.releaseTime - x.releaseTime),
          listPending: res.data.pending !== undefined ? res.data.pending : false
        });
      })
      .catch((e) => {});
  };

  componentDidMount() {
    let coinList = reactLocalStorage.getObject("coinList");
    let arr = [];
    coinList.forEach((coin) => {
      if (coin.category != "MAINNET") arr.push({ ...coin });
    });

    // console.log(`-- LOCK --`);
    this.setState({ coinList: arr, coinType: arr[0].coinType }, () => {
      this.getLockList();
    });
  }

  render() {
    const { visible, onCancel } = this.props;
    const { coinList, coinType, list, listPending } = this.state;
    const columns = [
      {
        key: "lockIdx",
        title: "Lock번호",
        dataIndex: "lockIdx",
        className: "text-center",
        render: (data) => <div>{numberFormat(data)}</div>
      },
      {
        title: "해제일",
        dataIndex: "releaseTime",
        className: "text-center",
        render: (data) => <div>{releaseTimeDate(data)}</div>
      },
      {
        title: "주소",
        dataIndex: "address",
        className: "text-center",
        render: (data) => <div>{checkData(data)}</div>
      },
      {
        title: "수량",
        dataIndex: "amount",
        className: "text-center",
        render: (data) => numberFormat(coinDivideFormat(data, coinType))
      },
      {
        title: "상태",
        dataIndex: "",
        className: "text-center",
        render: (data, row) =>
          releaseTimeDate(row.releaseTime) >
          formatDateSecond(
            moment()
              .endOf("day" - 1)
              .toString()
          ) ? (
            row.pending ? (
              "해제 요청 중 "
            ) : (
              <Button
                disabled={listPending ? true : false}
                onClick={() => {
                  this.unLock(row);
                }}
              >
                해지
              </Button>
            )
          ) : (
            "-"
          )
      }
    ];

    return (
      <Modal
        visible={visible}
        title="코인별 LOCK"
        onCancel={onCancel}
        style={{ minWidth: "1400px" }}
        footer={null}
      >
        <div className="flex-row flex-center flex-sb m-b-10">
          <div style={{ color: "red", textAlign: "center" }}>
            * Lock 해제를 요청중인 항목이 있으면 추가 요청이 불가능합니다.
          </div>
          <div>
            <label htmlFor="" style={{ marginRight: "20px" }}>
              코인
              <Select
                style={{ width: "160px", marginLeft: "10px" }}
                value={coinType !== 0 ? coinType : "선택해주세요"}
                onChange={this.handleChangeCoinType}
              >
                {coinList.map((coin) => (
                  <Option key={coin.coinType} value={coin.coinType}>
                    {coin.name}
                  </Option>
                ))}
              </Select>
            </label>
            <Button
              onClick={() => {
                this.getLockList();
              }}
            >
              검색
            </Button>
          </div>
        </div>
        <Table
          className="member-coinLock-table"
          rowKey={(record) => (record.idx ? record.idx : record.coinType)}
          rowClassName={(record) => {
            // console.log(moment().endOf("day" - 1));
            return releaseTimeDate(record.releaseTime) <=
              formatDateSecond(
                moment()
                  .endOf("day" - 1)
                  .toString()
              )
              ? "gray"
              : "";
          }}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default LockListModal;
