import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

import { Form, Icon, Input, Button, Checkbox } from "antd";
import crypto from "crypto";

import { httpPost, httpUrl } from "../api/httpClient";
import { login } from "../actions/loginAction";
import { getCoinDecimal, getLevelList } from "../contexts/asyncContext";

import Const from "../const";
import InputOtp from "../components/common/InputOtp";
import { ShowErrorAlert } from "../components/common/ErrorAlertModal";

const FormItem = Form.Item;
class Login extends Component {
  state = { otpVisible: false };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let password = values.password + values.id;
        let hashedPassword = crypto
          .createHash("sha256")
          .update(password, "utf8")
          .digest()
          .toString("hex");
        httpPost(httpUrl.login, [], {
          id: values.id,
          password: hashedPassword
        })
          .then((res) => {
            if (res.data.result) {
              this.props.getCoinDecimal({ language: "ko" });
              this.props.getLevelList({ language: "ko" });

              if (this.props.form.getFieldValue("remember")) {
                reactLocalStorage.set(
                  "adminId",
                  this.props.form.getFieldValue("id")
                );
                reactLocalStorage.set("idRemember", true);
              } else {
                reactLocalStorage.remove("adminId");
                reactLocalStorage.set("idRemember", false);
              }
              this.props.onLogin({
                ...res.data.adminUser,
                authList: res.data.adminAuth,
                authCoinList: res.data.adminAuthCoin
              });
              let path =
                res.data.adminAuth[0].subMenu === null
                  ? res.data.adminAuth[0].path
                  : res.data.adminAuth[0].subMenu[0].subMenu === null
                  ? res.data.adminAuth[0].subMenu[0].path
                  : res.data.adminAuth[0].subMenu[0].subMenu[0].path;
              this.props.history.push(path);
            } else {
              ShowErrorAlert(res.data.reason);
              if (res.data.reason === "WRONG_IPADDRESS") {
                this.setState({ otpVisible: false });
                this.props.form.resetFields(["id", "password"]);
              }
            }
          })
          .catch((error) => {});
      }
    });
  };

  componentDidMount() {
    let pathname = this.props.location.pathname.split("/");
    if (pathname[1] !== "changePassword") {
      let idRemember = reactLocalStorage.get("idRemember");
      if (idRemember === false || idRemember === "false") idRemember = false;
      else idRemember = true;
      let id = idRemember ? reactLocalStorage.get("adminId") : "";
      this.props.form.setFieldsValue({ id: id, remember: idRemember });
    }
  }

  render() {
    const { otpVisible } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="container">
        <div className="login-container">
          {otpVisible && (
            <InputOtp
              visible={otpVisible}
              onCancel={() => {
                this.setState({ otpVisible: false });
              }}
              onOk={this.optLogin}
            />
          )}
          <Form onSubmit={this.handleSubmit} className="login-form">
            {Const.isLogoFlag && (
              <div
                className="flex-row justify-center"
                style={{ marginBottom: "20px" }}
              >
                <img
                  src={Const.logoImage}
                  alt="logo"
                  style={{
                    width: "100%"
                  }}
                />
              </div>
            )}
            <FormItem>
              {getFieldDecorator("id", {
                rules: [{ required: true, message: "아이디를 입력해주세요" }]
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="아이디"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "비밀번호를 입력해주세요." }]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="비밀번호"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("remember", {
                valuePropName: "checked"
              })(<Checkbox className="checkbox mt-15">아이디 저장</Checkbox>)}
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: "100%" }}
              >
                Log in
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo)),
    getCoinDecimal: ({ language }) => dispatch(getCoinDecimal({ language })),
    getLevelList: ({ language }) => dispatch(getLevelList({ language }))
  };
};

let LoginForm = Form.create()(Login);
LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginForm);
export default withRouter(LoginForm);
