import React, { Component } from "react";

import { Form, Input, Modal } from "antd";
import stringTitle from "../../stringTitle";
import {
  showCreateRefreshConfirm,
  showUpdateRefreshCloseConfirm
} from "../common/AlerInfoModal";
import { httpUrl } from "../../api/httpClient";

const FormItem = Form.Item;
const UrlModal = Form.create()(
  class extends Component {
    state = {};

    onSubmit = (e) => {
      const { form, page, data } = this.props;

      e.preventDefault();
      form.validateFields((err, values) => {
        console.log(`values = ${JSON.stringify(values, null, 4)}`);
        let titleIdx = 0;
        let contentIdx = 0;
        let body = values;

        if (page === "edit") {
          titleIdx = data.titleIdx;
          contentIdx = data.contentIdx;
          body = {
            ...body,
            idx: data.idx
          };
        }

        body = {
          ...body,
          title: [
            {
              idx: titleIdx,
              content: values.title,
              language: "ko"
            }
          ],
          content: [
            {
              idx: contentIdx,
              content: values.content,
              language: "ko"
            }
          ]
        };
        console.log(`body = ${JSON.stringify(body, null, 4)}`);

        if (!err) {
          if (page === "create") {
            showCreateRefreshConfirm(
              stringTitle.createQuest,
              httpUrl.urlCreate,
              body,
              this.props.refresh,
              this.props.onCancel
            );
          }
          if (page === "edit") {
            showUpdateRefreshCloseConfirm(
              stringTitle.updateQuest,
              httpUrl.urlUpdate,
              body,
              this.props.refresh,
              this.props.onCancel,
              "update"
            );
          }
        }
      });
    };

    componentDidMount() {
      // console.log(`props = ${JSON.stringify(this.props, null, 4)}`);
      const { page, form, data } = this.props;

      if (page === "edit")
        form.setFieldsValue({
          ...data,
          title: data.title[0].content,
          content: data.content[0].content
        });
    }

    render() {
      const { visible, onCancel, form, page } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 5 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      };

      return (
        <Modal
          visible={visible}
          onCancel={onCancel}
          onOk={this.onSubmit}
          title={stringTitle.url}
          okText={page === "create" ? stringTitle.create : stringTitle.update}
          cancelText={stringTitle.cancel}
          maskClosable={false}
          style={{ minWidth: 800 }}
        >
          <Form {...formItemLayout}>
            <FormItem label={<span>{stringTitle.title}&nbsp;</span>}>
              {getFieldDecorator("title", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: stringTitle.titlePlaceholder
                  }
                ]
              })(<Input allowClear />)}
            </FormItem>
            <FormItem label={<span>{stringTitle.content}&nbsp;</span>}>
              {getFieldDecorator("content", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: stringTitle.contentPlaceholder
                  }
                ]
              })(<Input.TextArea rows={4} />)}
            </FormItem>
            <FormItem
              label={<span>{stringTitle.url}&nbsp;</span>}
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator("url", {
                validateTrigger: ["onChange", "onBlur"],
                rules: [
                  {
                    required: true,
                    message: stringTitle.urlPlaceholder
                  }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem label={<span>&nbsp;</span>} colon={false}>
              유튜브 url 입력시 https://www.youtube.com/watch?v=
              <span style={{ color: "red" }}>u4qUdtIdENM</span>
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);
export default UrlModal;
