import React, { Component } from "react";

import { Button, Table } from "antd";
import { httpUrl, httpGet } from "../../api/httpClient";

import { numberFormat } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../../components/common/SelectBox";
import { showDeleteIdxConfirm } from "../../components/common/AlerInfoModal";

import LevelModal from "../../components/level/LevelModal";
import { ShowErrorAlert } from "../../components/common/ErrorAlertModal";

class LevelList extends Component {
  state = {
    list: [],

    visible: false,
    type: "create"
  };

  onDelete = (data) => {
    showDeleteIdxConfirm(
      stringTitle.deleteQuest,
      httpUrl.levelDelete,
      data,
      this.getLevelList
    );
  };

  getLevelList = () => {
    httpGet(httpUrl.getLevelList, [], {})
      .then((res) => {
        // console.log(`level list = ${JSON.stringify(res.data, null, 4)}`);
        this.setState({ list: res.data });
      })
      .catch((error) => {
        ShowErrorAlert(error.response.data.message || error.message);
      });
  };

  componentDidMount() {
    this.getLevelList();
  }

  render() {
    const { visible, type, list } = this.state;
    const columns = [
      // {
      //   key: "idx",
      //   dataIndex: "idx",
      //   title: stringTitle.idx,
      //   className: "text-center",
      //   render: (data) => numberFormat(data)
      // },
      {
        dataIndex: "level",
        title: stringTitle.level,
        className: "text-center",
        render: (data) => numberFormat(data)
      },
      {
        dataIndex: "name",
        title: stringTitle.name,
        className: "text-center",
        render: (data, row) => (
          <div
            className="pointer"
            onClick={
              () => alert("서비스 준비중입니다.")
              // this.setState({visible: true, type: 'edit', levelData: row })
            }
          >
            {data.find((item) => item.language === global.language).content}
          </div>
        )
      },
      {
        title: stringTitle.status,
        dataIndex: "deleted",
        className: "text-center",
        render: (data, row) => (
          <SelectBox
            value={string.deleteString[data]}
            code={string.toggleCode}
            codeString={string.deleteString}
            onChange={(value) => {
              if (parseInt(value) !== data) {
                this.onDelete(row);
              }
            }}
          />
        )
      }
    ];

    return (
      <div>
        {visible && (
          <LevelModal
            visible={visible}
            type={type}
            onCancel={() => {
              this.setState({ visible: false });
            }}
            refresh={this.getLevelList}
          />
        )}
        <div className="title">
          {stringTitle.level + stringTitle.management}
        </div>
        <Button
          className="m-b-10"
          onClick={() => {
            this.setState({ visible: true, type: "create" });
          }}
        >
          {stringTitle.create}
        </Button>
        <div style={{ clear: "both" }} />
        <Table
          rowKey={(record) => record.idx}
          columns={columns}
          dataSource={list}
          pagination={false}
        />
      </div>
    );
  }
}

export default LevelList;
