import { fetchStart, fetchSuccess, fetchRejected } from "../actions/asyncFetch";
import { coinStart, coinSuccess, coinRejected } from "../actions/coinFetch";
import { httpGet, httpUrl } from "../api/httpClient";
import { reactLocalStorage } from "reactjs-localstorage";

export const getBlockChain = (params = {}) => {
  return (dispatch) => {
    httpGet(httpUrl.blockChainList, [params.searchText], {})
      .then((res) => {
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchRejected(error));
      });
  };
};

export const getCoinDecimal = (params = {}) => {
  return (dispatch) => {
    httpGet(httpUrl.getCoinDecimal, [params.language], {})
      .then((res) => {
        reactLocalStorage.setObject("coinList", res.data);
        dispatch(coinStart(true));
        dispatch(coinSuccess(res.data));
      })
      .catch((error) => {
        dispatch(coinRejected(error));
      });
  };
};

export const getLevelList = (params = {}) => {
  return (dispatch) => {
    httpGet(httpUrl.getLevelListLanguage, [params.language], {})
      .then((res) => {
        reactLocalStorage.setObject("levelList", res.data);
        dispatch(fetchStart(true));
        dispatch(fetchSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchRejected(error));
      });
  };
};
