import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { connect } from "react-redux";
import crypto from "crypto";

import { Modal, Descriptions, Input } from "antd";
import { login } from "../../actions/loginAction";
import { httpPut, httpUrl } from "../../api/httpClient";

import { formatDate } from "../../util";
import string from "../../string";
import stringTitle from "../../stringTitle";
import SelectBox from "../common/SelectBox";
import { ShowErrorAlert } from "../common/ErrorAlertModal";

const Ditems = Descriptions.Item;
class AdminDetail extends Component {
  state = {
    updateData: {}
  };

  onChangeModal = (value, type) => {
    var data = Object.assign({}, this.state.updateData);
    data[type] = value;
    this.setState({
      updateData: data
    });
  };

  onUpdate = () => {
    let updateData = this.state.updateData;
    if (updateData.ipAddress === "") alert("IP 주소를 정확히 입력해주세요.");
    if (updateData.password !== null) {
      let password = updateData.password + updateData.userId;
      let hashedPassword = crypto
        .createHash("sha256")
        .update(password, "utf8")
        .digest()
        .toString("hex");
      updateData = { ...updateData, password: hashedPassword };
    }
    // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
    Modal.confirm({
      width: 500,
      title: stringTitle.updateQuest,
      okText: stringTitle.check,
      onOk() {
        httpPut(httpUrl.adminUpdate, [], updateData)
          .then((res) => {
            // console.log(`res => ${JSON.stringify(res, null, 4)}`);
            if (res.data.result === "SUCCESS") {
              Modal.info({
                title: stringTitle.updateAnw,
                okText: stringTitle.check,
                onOk() {
                  delete updateData.password;
                  this.props.onOk();
                  this.props.onCancel();

                  const userInfo = reactLocalStorage.getObject("adminUser");
                  if (updateData.idx === userInfo.idx) {
                    let adminData = {
                      ...userInfo,
                      ...updateData
                    };
                    this.props.onLogin({ ...adminData });
                  }
                }
              });
            } else ShowErrorAlert(res.data.data);
          })
          .catch((error) => {
            ShowErrorAlert(error.response.data.message || error.message);
          });
      },
      cancelText: stringTitle.cancel,
      onCancel() {}
    });
  };

  componentDidMount() {
    this.setState({
      updateData: this.props.detailData
    });
  }

  render() {
    const { visible, onCancel } = this.props;
    const { updateData } = this.state;
    const userInfo = reactLocalStorage.getObject("adminUser");

    return (
      <Modal
        visible={visible}
        title={stringTitle.admin + stringTitle.info}
        okText={stringTitle.modify}
        cancelText={stringTitle.cancel}
        onOk={this.onUpdate}
        onCancel={onCancel}
        style={{ minWidth: "1000px" }}
        maskClosable={false}
      >
        <Descriptions
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >
          <Ditems label={stringTitle.createDate}>
            {formatDate(updateData.createDate)}
          </Ditems>
          <Ditems label={stringTitle.admin + stringTitle.level}>
            {userInfo.superAdmin === 1 ? (
              <SelectBox
                type="superAdmin"
                style={{ width: "160px" }}
                value={string.adminString[updateData.superAdmin]}
                code={string.toggleCode}
                codeString={string.adminString}
                onChange={this.onChangeModal}
              />
            ) : (
              string.adminString[updateData.superAdmin]
            )}
          </Ditems>
          <Ditems label={stringTitle.id}>{updateData.userId}</Ditems>
          <Ditems label={stringTitle.name}>
            <Input
              value={updateData.name}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "name");
              }}
            />
          </Ditems>
          <Ditems label={stringTitle.password}>
            <Input.Password
              value={updateData.password}
              placeholder="변경 시 작성"
              onChange={(e) => {
                this.onChangeModal(e.target.value, "password");
              }}
            />
          </Ditems>
          <Ditems label={stringTitle.ip}>
            <Input
              placeholder="123.456.0.0"
              value={updateData.ipAddress}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "ipAddress");
              }}
            />
            <div
              style={{
                fontSize: "14px",
                color: "blue"
              }}
            >
              <span>*</span>을 입력하시면 모든 곳에서 접속이 가능합니다.
            </div>
          </Ditems>
          <Ditems label={stringTitle.phone}>
            <Input
              style={{ width: "200px" }}
              value={updateData.mobile}
              maxLength={11}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9]+$/g, "");
                this.onChangeModal(value, "mobile");
              }}
            />
          </Ditems>
          <Ditems label={stringTitle.email}>
            <Input
              value={updateData.email}
              onChange={(e) => {
                this.onChangeModal(e.target.value, "email");
              }}
            />
          </Ditems>
        </Descriptions>
      </Modal>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    isLogin: state.login.isLogin,
    loginInfo: state.login.loginInfo
  };
};

let mapDisPatchToProps = (dispatch) => {
  return {
    onLogin: (userinfo) => dispatch(login(userinfo))
  };
};

export default connect(mapStateToProps, mapDisPatchToProps)(AdminDetail);
