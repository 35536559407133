import _const from "./const";
import stringTitle from "./stringTitle";

const string = {
  // 대회
  competitionRequestString: {
    REQUEST: stringTitle.request,
    ACCEPT: stringTitle.accept,
    REJECT: stringTitle.reject
  },

  // 회원
  typeCode: ["FROM", "TO"],
  cautionString: {
    FROM: stringTitle.cautionFrom,
    TO: stringTitle.cautionTo
  },
  activeCode: ["ALL", "ACTIVE", "NOT"],
  activeString: {
    ALL: stringTitle.all,
    ACTIVE: stringTitle.active,
    NOT: stringTitle.hold
  },
  distributeCode: ["ALL", "MANUAL", "AUTO"],
  distributeString: {
    ALL: stringTitle.all,
    MANUAL: stringTitle.manual,
    AUTO: stringTitle.auto
  },

  // 통계
  partnerStatistics: {
    DAY: stringTitle.day,
    WEEK: stringTitle.week,
    MONTH: stringTitle.month
  },
  shareStatistics: {
    WEEK: stringTitle.week,
    MONTH: stringTitle.month
  },

  searchTypeString: {
    ID: stringTitle.id,
    ADDRESS: stringTitle.address
  },
  walletLogCode: ["ALL", "FROM", "TO"],
  transferSearchString: {
    ALL: stringTitle.all,
    FROM: stringTitle.from,
    TO: stringTitle.to
  },
  walletLogString: _const.isFoundationInFee
    ? {
        ALL: stringTitle.all,
        FROM: stringTitle.from,
        TO: stringTitle.to,
        SERVER: stringTitle.foundation + stringTitle.airdrop
      }
    : {
        ALL: stringTitle.all,
        FROM: stringTitle.from,
        TO: stringTitle.to
      },
  sendString: {
    I2I: stringTitle.I2I,
    I2E: stringTitle.I2E,
    E2I: stringTitle.E2I,
    S2I: stringTitle.S2I,
    AIR: stringTitle.airdrop
  },
  transferString: {
    ALL: stringTitle.all,
    I2I: stringTitle.I2I,
    I2E: stringTitle.I2E,
    E2I: stringTitle.E2I,
    AIR: stringTitle.airdrop
  },
  requestLogCode: ["ALL", "REQUEST", "SUCCESS", "PENDING", "FAIL"],
  requestLogString: {
    ALL: stringTitle.all,
    REQUEST: stringTitle.request,
    SUCCESS: stringTitle.success,
    PENDING: stringTitle.pending,
    FAIL: stringTitle.fail
  },
  lockRequestCode: ["SET_LOCK", "UN_LOCK"],
  lockRequesetString: {
    ALL: stringTitle.all,
    SET_LOCK: "LOCK",
    UN_LOCK: "UNLOCK"
  },
  lockRequestSearchCode: ["ID", "ADDRESS", "TXID"],
  lockRequesetSearchString: {
    ID: stringTitle.id,
    ADDRESS: stringTitle.address,
    TXID: "TXID"
  },

  distributeTypeString: {
    MANUAL: stringTitle.manual,
    LOGIN: stringTitle.login,
    RECMND: stringTitle.RECMND,
    RECMNDED: stringTitle.RECMNDED,
    NOT: stringTitle.NOT
  },

  autoString: {
    SIGN_UP_FEE: stringTitle.sign_up_fee,
    SIGN_UP: stringTitle.sign_up,
    SIGN_UP_REC: stringTitle.sign_up_rec
  },

  // 공지사항
  noticeCode: ["ALL", "NOTE", "EVNT", "NEWS"],
  noticeString: {
    ALL: stringTitle.all,
    NOTE: stringTitle.note,
    EVNT: stringTitle.evnt,
    NEWS: stringTitle.news
  },
  noticeSelectCode: ["NOTE", "EVNT", "NEWS"],
  noticeSelect: {
    NOTE: stringTitle.note,
    EVNT: stringTitle.evnt,
    NEWS: stringTitle.news
  },

  // 감사
  auditCode: ["ALL", "SUCCESS", "FAIL"],
  auditString: {
    ALL: stringTitle.all,
    SUCCESS: stringTitle.success,
    FAIL: stringTitle.fail
  },

  // 언어
  language: ["ko", "en"],
  languageString: {
    ko: "한국어",
    en: "ENGLISH"
  },

  // 정렬
  orderByCode: ["ASC", "DESC"],
  orderByString: {
    ASC: stringTitle.asc,
    DESC: stringTitle.desc
  },

  // toggle
  toggleCode: [0, 1],
  activeToggleCode: ["ACTIVE", "NOT"],
  deleteString: {
    0: "-",
    1: stringTitle.delete
  },
  activeHandlesCodes: {
    0: stringTitle.hold,
    1: stringTitle.active
  },
  adminString: {
    0: stringTitle.generalManager,
    1: stringTitle.superAdmin
  },
  authString: {
    0: stringTitle.impossible,
    1: stringTitle.possible
  },
  loginString: {
    0: stringTitle.fail,
    1: stringTitle.success
  }
};

export default string;
